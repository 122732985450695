import React, { useState, useCallback } from 'react';
import { AutoComplete, Select } from 'antd';
import { debounce, capitalize } from 'lodash';
import Api from 'api';
const { Option } = Select;

interface SearchProps {
  type: string;
  searchKey?: string;
  placeholder?: string;
  collectionId?: string;
  database?: string;
  onSelect: Function;
  excludeIds?: string[]
}
export default function SearchInput({
  type,
  searchKey = "all",
  collectionId = "",
  database = "all",
  onSelect,
  placeholder,
  excludeIds = [],
}: SearchProps) {
  // const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const getSearchResults = async (word) => {
    const showPrefix = false;
    if (!word || word === "") {
      setOptions([]);
    }
    try {
      const params: any = {
        collection_id: collectionId,
        key: searchKey,
        term: word,
        // database,
      };
      if (type === "animal") {
        params.database = database;
      }
      // Need to decide if we want to include this. old supports excludeId on backend but not on frontend
      // if(excludeIds && excludeIds.length > 0) {
      //   params.exclude_ids = excludeIds;
      // }
      const { data } = await Api.get(`/${type}s`, params);
      if (data && data.results) {
        // TODO: need to add new/old logic
        let _options = [];
        if (type === "animal") {
          _options = data.results.map((item) => {
            let value = "";
            if (searchKey === "scientific_names") {
              if (item.scientific_names?.toLowerCase().startsWith(word)) {
                value =
                  `${showPrefix ? "[SNS]" : ""}` +
                  capitalize(item.scientific_names);
              }
            } else if (searchKey === "common_names") {
              value =
                `${showPrefix ? "[CN]" : ""}` + capitalize(item.common_names);
            } else {
              value =
                `${showPrefix ? "[SN]" : ""}` +
                `${item.common_names ? item.common_names : ''} - ${item.scientific_names}`; // Note: missing common names cases
            }
            const isNew = !!excludeIds.includes(item.id);
            return {
              ...item,
              value: `${value} ${isNew ? "(already added)" : ""}`,
              key: item.id,
            };
          });
        } else {
          _options = data.results.map((item) => {
            let value = "";
            if (searchKey === "scientific_names") {
              value = `${showPrefix ? "[SNS]" : ""}` + capitalize(item.name);
              if (item.name.toLowerCase().indexOf(word.toLowerCase()) === -1) {
                if (item.synonyms.length > 0) {
                  if(Array.isArray(item.synonyms)) {
                    value += "(";
                    item.synonyms.forEach((alt_name) => {
                      value += capitalize(alt_name) + ",";
                    });
                    value = value.replace(/.$/, ")");
                  } else {
                    value += `(${item.synonyms})`;
                  }
                }
              }
              if(value.length === 0) {
                value = capitalize(item.full_name);
              }
            } else if (searchKey === "common_names") {
              value = `${showPrefix ? "[SN]" : ""}` + item.common_names;
              if (value.length === 0) {
                value = capitalize(item.full_name);
              }
            } else {
              value = `${showPrefix ? "[SNE]" : ""}` + capitalize(item.name);
              if (item.name.toLowerCase().indexOf(word.toLowerCase()) === -1) {
                if (item.synonyms.length > 0) {
                  if(Array.isArray(item.synonyms)) {
                    value += "(";
                    item.synonyms.forEach((alt_name) => {
                      value += capitalize(alt_name) + ",";
                    });
                    value = value.replace(/.$/, ")");
                  } else {
                    value += `(${item.synonyms})`;
                  }
                }
              }
              if (value.length === 0) {
                value = capitalize(item.full_name);
              }
            }
             const isNew = !!excludeIds.includes(item.id);
            return {
              ...item,
              value: `${value} ${isNew ? "(already added)" : ""}`,
              key: item.id,
            };
          });
        }
        setOptions(_options);
        // setOptions(
        //   )
        // );
      }
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Search";
      console.log(e);
      //   message.error({ content: errorMsg, duration: 3 });
    }
  };
  const debouncedSave = useCallback(
    debounce((newValue) => getSearchResults(newValue), 1000),
    [excludeIds, searchKey, database]
  );
  const updateValue = (newValue) => {
    debouncedSave(newValue);
  };

  return (
    // <div>
    <AutoComplete
      autoClearSearchValue
      // value={null}
      options={options}
      className='autocompleteSearchInput'
      style={{ width: "100%" }}
      dropdownMatchSelectWidth={false}
      onSelect={(val, opt: any) => {
        if(excludeIds.includes(opt.id)) {
          return false;
        }
        onSelect(opt.id);
      }}
      onSearch={(input) => updateValue(input)}
      placeholder="Enter species here"
    />
    // </div>
  );
  // return (
  //   <Select
  //     showSearch
  //     searchValue={searchText}
  //     style={{ width: "100%" }}
  //     onSelect={onSelect}
  //     onSearch={(input) => updateValue(input)}
  //     placeholder="Enter species here"
  //     // notFoundContent=""
  //   >
  //     <Option value="jack">Jack</Option>
  //     {options.map((o: any) => {
  //       console.log("lloing", o.id);
  //       return (
  //         <Option key={o.id} value={o.id}>
  //           {o.label}sss
  //         </Option>
  //       );
  //     })}
  //   </Select>
}