import React, { useState } from "react";
import { Modal, Button, Input, message, Alert, notification } from "antd";
import { useHistory } from "react-router-dom";
import Api from "api";
import { cloneCollection } from "api/helper";

const CloneCollectionModal = ({ isOpen, onClose, collection }) => {
  const [name, setName] = useState<string>(`${collection.attributes.name} - Copy`);
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const history = useHistory();
  const handleCloneCollection = async () => {
    setLoading(true);
    const colRes = await cloneCollection(collection.id, name);
    setLoading(false);
    if (colRes && colRes.data && colRes.data.id) {
      notification.success({
        message: "Success",
        description: `Cloned collection '${collection.attributes.name}' as '${name}'`,
      });
      onClose("success");
      history.push(`/collections/${colRes.data.id}`);
    } else {
      notification.error({
        message: "Error",
        description: colRes.error,
      });
      onClose();
    }
  };
  return (
    <Modal
      title="Clone the collection"
      style={{ textAlign: "center", fontWeight: "bold" }}
      visible={isOpen}
      onCancel={() => onClose("cancel")}
      footer={[
        <Button key="back" onClick={() => onClose("cancel")}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleCloneCollection}
        >
          Submit
        </Button>,
      ]}
    >
      {err && err.length && (
        <Alert message={err} type="error" style={{ marginBottom: 10 }} />
      )}
      <Input
        // rows={4}
        value={name}
        placeholder="New Name"
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </Modal>
  );
};
export default CloneCollectionModal;

