export const typeMapping = {
  "What was the house number and street name you lived in as a child?": {
    message: "Answer not valid",
  },
  "What were the last four digits of your childhood telephone number?": {
    regex: /^\d{4}$/,
    truth: true,
    message: "Enter 4 digits",
  },
  "What primary school did you attend?": {
    message: "Answer not valid",
  },
  "In what town or city was your first full time job?": {
    regex: "",
    message: "Answer not valid",
  },
  "In what town or city did you meet your spouse/partner?": {
    regex: "",
    message: "Answer not valid",
  },
  "What is the middle name of your oldest child?": {
    regex: /[^a-z A-Z]/,
    truth: false,
    message: "Enter valid name",
  },
  "What are the last five digits of your driver's licence number?": {
    regex: /^\d{5}$/,
    truth: true,
    message: "Answer not valid",
  },
  "What is your grandmother's (on your mother's side) maiden name?": {
    regex: /[^a-z A-Z]/,
    truth: false,
    message: "Enter valid name",
  },
  "What is your spouse or partner's mother's maiden name?": {
    regex: /[^a-z A-Z]/,
    truth: false,
    message: "Enter valid name",
  },
  "In what town or city did your mother and father meet?": {
    regex: "",
    message: "Answer not valid",
  },
  "What time of the day were you born? (hh:mm)": {
    regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    truth: true,
    message: "Enter valid hh:mm",
  },
  "What time of the day was your first child born? (hh:mm)": {
    regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    truth: true,
    message: "Enter valid hh:mm",
  },
};
