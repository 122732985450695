import React, { useState } from "react";
import styled from "styled-components";
import { setToken, setUserData } from "../../helper";
import {
  Form,
  Input,
  Button,
  Col,
  Checkbox,
  message,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Api from "api";
import { ThemeConfig } from "theme";

const LoginWrapper = styled.div`
  width: 100vw;
  margin-top: 70px;
  min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;


interface Props {}

const LoginForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>();

  const login = async (values) => {
    setLoading(true);
    try {
      const { data } = await Api.post("/auth/signin", values);
      setLoading(false);
      if (data && data.token) {
        await setToken(data.token.split('JWT ')[1]);
        await setUserData(
          JSON.stringify({
            email: values.email,
          })
        );
        window.location.reload();
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg = (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
          "Failed to login"
     console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <LoginWrapper>
      <FormWrapper>
        <Col lg={{ span: 8, offset: 8 }}>
          <h4 className="mb-3" style={{ color: "#494848" }}>
            PLEASE SIGN IN YOUR ACCOUNT
          </h4>
          <Form
            {...layout}
            form={form}
            onFinish={login}
            name="login"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              // label="Email"
              name="email"
              labelAlign="left"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Enter a valid email" },
              ]}
            >
              <Input
                // className="mb-3"
                placeholder="Email"
                // style={{ paddingTop: 6, paddingBottom: 6 }}
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              labelAlign="left"
              rules={[{ required: true, message: "Enter your password" }]}
            >
              <Input.Password
                className="mb-3"
                placeholder="Password"
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                style={{ paddingTop: 6, paddingBottom: 6 }}
              />
            </Form.Item>
            <div className="checkboxWrapper">
              <Checkbox onChange={() => {}}>Remember Me</Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit"
                // onClick={login}
                loading={loading}
              >
                Login
              </Button>
            </div>
            <div
              className="mtb10"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 14,
              }}
            >
              Register with new account? Signup{" "}
              <a
                href="/signup"
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  fontWeight: 400,
                  color: ThemeConfig.colors.green,
                }}
              >
                here
              </a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: ThemeConfig.colors.grey,
              }}
            >
              <a className="greyLink" href="/forgot_password">
                Forgot your password?
              </a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a className="greyLink" href="/resend_confirmation">
                Resend Confirmation mail
              </a>
            </div>
          </Form>
        </Col>
      </FormWrapper>
    </LoginWrapper>
  );
};

export default LoginForm;
