import React, { useState, useEffect } from "react";
import Container, { DashboardContainer } from "components/Container/Container";
import CollectionItemsTable from "components/Collection/CollectionItemsTable";
// import AddCollectionModal from "components/AddCollectionModal/AddCollectionModal";

interface Props {}


const SingleCollection = (props: Props) => {

  return (
    <DashboardContainer>
        <CollectionItemsTable
        type="animal"
      />
    </DashboardContainer>
  );
};

export default SingleCollection;
