import React from 'react';
// import styled from 'styled-components';

import RealFontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
const FontAwesomeIcon = (props) =>  {
    return <RealFontAwesomeIcon {...props} size={props.sidebar ? 16 : (props.size ? props.size : 16)} color={props.color || 'white'}  style={{paddingRight: props.sidebar ? 10 : 0}}/>
}

export default FontAwesomeIcon;

// const FontAwesomeIcon = styled(RealFontAwesomeIcon)`
//   padding-right: 10px;

  
// `;

// export default FontAwesomeIcon;