import config from "../config";
import { io } from "socket.io-client";

export const initiateSocket = (user) => {
  console.log("initializing user socket", user);
  const socket = io(config.socketURL, {
    reconnectionDelayMax: 10000,
    query: {
      userID: user.id,
    },
  });
  socket.on("connect", () => console.log(`Client connected: ${socket.id}`));

  socket.on("disconnect", (reason) =>
    console.log(`Client disconnected: ${reason}`)
  );

  socket.on("connect_error", (reason) =>
    console.log(`Client connect_error: ${reason}`)
  );
  return socket;
};