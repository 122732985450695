import React, { useEffect, useState } from 'react';
import {  Layout as AntdLayout, message, Result, Button} from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import ReConfirmForm from 'components/Login/ReConfirmForm';
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Api from 'api';
import { setToken } from 'helper';
import { LoadingSpinnerView } from 'components/LoadingSpinner/LoadingSpinner';
interface Props {}
const LoginWrapper = styled.div`
  width: 100vw;
  margin-top: 70px;
  min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;
const ConfirmView = (props: Props) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>();
  const [result, setResult] = useState<string>();
  const handleConfirm = async (token) => {
    setLoading(true);
    try {
      const { data } = await Api.post("/auth/confirm_account", {
        token
      });
      setLoading(false);
      if(data && data.token) {
        await setToken(data.token.split("JWT ")[1]);
        setResult('Account confirmed Successfully');
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to confirm account";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  useEffect(() => {
    const queries = qs.parse(location.search);
    if (!queries || !queries.token) {
      message.error({ content: "Invalid confirmation url", duration: 5 });
    } else {
      handleConfirm(queries.token);
    }
  }, [location.search]);
  if (loading) return <LoadingSpinnerView size={80} />;
  if (result && result.length) {
    return (
      <AntdLayout>
        <HeaderNoAuth />
        <LoginWrapper>
        <Result
          status="success"
          title="Success!"
          subTitle={result}
          extra={[
            <Button type="primary" key="console">
              <a href="/">Ok</a>
            </Button>,
          ]}
        />
        </LoginWrapper>
      </AntdLayout>
    );
  }
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <ReConfirmForm />
    </AntdLayout>
  );
};

export default ConfirmView;
