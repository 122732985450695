import React from 'react';
import styled from "styled-components";
import {
  MenuOutlined,
} from "@ant-design/icons";
import { Button } from 'antd';
import { ThemeConfig } from 'theme';

interface Props {
  toggleSidebar: Function;
  collapse: boolean;
  isMobile: boolean;
}
const FloatingWrapper = styled.div`
  position: fixed;
  top: 86px;
  left: 10px;
  z-index: 2;
`;
const FloatingSideMenu: React.FC<Props> = ({ toggleSidebar, collapse }) => {
  return (
    <FloatingWrapper className="floatingSideWrapper">
      <Button
        type="link"
        onClick={() => {
          toggleSidebar(!collapse);
        }}
        style={{color: ThemeConfig.colors.darkBlue, fontSize: '20px',fontWeight: 'bold'}}
      >
        <MenuOutlined />
      </Button>
    </FloatingWrapper>
  );
};

export default FloatingSideMenu;
