import React, { useState, useEffect } from "react";
import Container, { DashboardContainer } from "components/Container/Container";
import AddCollectionModal from "components/Collection/AddCollectionModal";
import UploadCollectionFileModal from "components/Collection/UploadCollectionFileModal";
import { Button, Result } from "antd";

// import AddCollectionModal from "components/AddCollectionModal/AddCollectionModal";

interface Props {}


const UploadCollectionView = (props: Props) => {
  const [showModal, setShowModal] = useState(true);
  return (
    <DashboardContainer>
      {!showModal && (
        <Result
          status="warning"
          title="Upload seems to be cancelled, Please upload again"
          extra={
            <Button type="primary" key="console" onClick={() => setShowModal(true)}>
              Upload
            </Button>
          }
        />
      )}
      <UploadCollectionFileModal
        onClose={() => {
          setShowModal(false);
        }}
        visible={showModal}
      />
    </DashboardContainer>
  );
};

export default UploadCollectionView;
