import React, { useState, useEffect } from "react";
import { DashboardContainer } from "components/Container/Container";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import { Button } from "antd";

import AddCollectionModal from "components/Collection/AddCollectionModal";
import CollectionTable from "components/Collection/CollectionTable";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import UploadCollectionFileModal from "components/Collection/UploadCollectionFileModal";
import Api from "api";

interface Props {}

const PAGE_LIMIT = 10;

const Collections = (props: Props) => {
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [collections, setCollections] = useState<null | Array<Object>>([]);
  const [plantsLoading, setPlantsLoading] = useState(true);
  const [animalsLoading, setAnimalsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [plantCollections, setPlantCollections] = useState([]);
  const [animalCollections, setAnimalCollections] = useState([]);
  const fetchCollections = async (type) => {
    try {
      const { data } = await Api.get(`/collections/${type}`, {});
      if(data && data.data) {
        if(type === 'plants') {
          setPlantCollections(data.data);
        } else {
          setAnimalCollections(data.data);
        }
      }
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch Collections";
      console.log(errorMsg);
    }
    type === "plants" && setPlantsLoading(false);
    type === "animals" && setAnimalsLoading(false);
    setLoading(false);
  };
  useEffect(() => {
    fetchCollections('plants')
    fetchCollections('animals')
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    // setTableLoading(true);
  };

  const onEditCollection = (selCollection) => {
    setShowAddCollectionModal(true);
  };



  const onDeleteCollection = (collection) => {
    // setTableLoading(true);
  };

  const refetch = async (type) => {
    fetchCollections(type);
  }

  if (loading) return <LoadingSpinnerView size={80} />;

  return (
    <DashboardContainer>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="primary"
          size="small"
          onClick={() => setShowAddCollectionModal(true)}
          style={{ marginRight: 10 }}
        >
          <PlusOutlined />
          <span>Add New Collection</span>
        </Button>
        <Button
          type="primary"
          onClick={() => setShowUploadModal(true)}
          size="small"
        >
          <UploadOutlined />
          <span>Upload File</span>
        </Button>
      </div>
      <CollectionTable
        type="plant"
        collections={plantCollections}
        tableLoading={plantsLoading}
        pageLimit={PAGE_LIMIT}
        handleTableChange={handleTableChange}
        // pagination={paginationData}
        onEditCollection={onEditCollection}
        onDeleteCollection={onDeleteCollection}
        refetch={() => {
          refetch("plants");
        }}
      />
      <CollectionTable
        type="animal"
        collections={animalCollections}
        tableLoading={animalsLoading}
        pageLimit={PAGE_LIMIT}
        handleTableChange={handleTableChange}
        // pagination={paginationData}
        onEditCollection={onEditCollection}
        onDeleteCollection={onDeleteCollection}
        refetch={() => {
          refetch("animals");
        }}
      />
      <AddCollectionModal
        onClose={() => {
          setShowAddCollectionModal(false);
        }}
        visible={showAddCollectionModal}
      />
      <UploadCollectionFileModal
        onClose={() => {
          setShowUploadModal(false);
        }}
        visible={showUploadModal}
      />
    </DashboardContainer>
  );
};

export default Collections;
