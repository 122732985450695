import React, { useEffect, useState } from 'react';
import {  Layout as AntdLayout, message } from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import ResetPasswordForm from 'components/Login/ResetPasswordForm';
interface Props {}
const ResetPasswordView = (props: Props) => {
  const location = useLocation();
  const [resetToken, setResetToken] = useState<string>();
  
  useEffect(() => {
    const queries = qs.parse(location.search);
    if (queries && queries.token) {
       setResetToken(queries.token as string);
    } else {
      message.error({content: 'Invalid reset token', duration: 3});
    }
  }, [location.search]);
  return (
    <AntdLayout>
      <HeaderNoAuth />
      {!!resetToken && <ResetPasswordForm resetToken={resetToken}/>}
    </AntdLayout>
  );
};

export default ResetPasswordView;
