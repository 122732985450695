import React, { useEffect, useState } from 'react';
import { StyledSidebar } from './sidebarStyles';
import { Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import { DoubleRightOutlined, DoubleLeftOutlined, HomeOutlined, ImportOutlined, UserAddOutlined, UnorderedListOutlined, PlusOutlined, AppstoreAddOutlined, ExportOutlined } from '@ant-design/icons';
import { ThemeConfig } from 'theme';
import { capitalize } from "lodash";
import InviteAUserModal from 'components/Models/InviteAUserModal';
import FontAwesomeIcon from 'components/Common/FontAwesomeIcon';
import Api from 'api';
import AddCollectionModal from 'components/Collection/AddCollectionModal';
import CollaborateCollectionModal from 'components/Models/CollaborateCollectionModal';
import UploadCollectionFileModal from 'components/Collection/UploadCollectionFileModal';
import ExportAListModal from 'components/Models/ExportAListModal';
// import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
interface Props {
  collapse: boolean;
  toggleSidebar: Function;
  isMobile: boolean;
}
const { SubMenu } = Menu;
const Sidebar: React.FC<Props> = ({ collapse, toggleSidebar, isMobile }) => {
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const [collaborateModalOpen, setCollaborateModalOpen] = useState<boolean>(false);
  const [plantCollections, setPlantCollections] = useState([]);
  const [animalCollections, setAnimalCollections] = useState([]);
  const [showAddPlant, setShowAddPlant] = useState<boolean>(false);
  const [showAddAnimal, setShowAddAnimal] = useState<boolean>(false);
  const [showImportAList, setShowImportAList] = useState<boolean>(false);
  const [showExportAList, setShowExportAList] = useState<boolean>(false);
  const [showAddColl, setShowAddcoll] = useState<boolean>(false);
  const fetchCollections = async (type) => {
    try {
      const { data } = await Api.get(`/collections/${type}`, {});
      if (data && data.data) {
        if (type === "plants") {
          setPlantCollections(data.data);
        } else {
          setAnimalCollections(data.data);
        }
      }
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch collections";
      console.log(errorMsg);
    }
  };
  let activeMenu = window.location.pathname.toString();
  useEffect(() => {
    fetchCollections("plants");
    fetchCollections("animals");
  }, []);
  return (
    <StyledSidebar
      trigger={null}
      width={250}
      collapsible
      collapsed={collapse}
      $isMobile={isMobile}
    >
      <Menu
        theme="dark"
        // mode="vertical"
        mode="inline"
        defaultSelectedKeys={[activeMenu]}
        selectedKeys={[activeMenu]}
      >
        <Menu.Item
          key="toggle"
          title={null}
          style={{ backgroundColor: ThemeConfig.colors.darkerBlue }}
          onClick={() => {
            toggleSidebar(!collapse);
          }}
        >
          <Button
            style={{
              backgroundColor: "transparent",
              border: "none",
              textAlign: "left",
              padding: 0,
              paddingRight: 30,
            }}
          >
            {collapse ? (
              <DoubleRightOutlined style={{ color: ThemeConfig.colors.text }} />
            ) : (
              <DoubleLeftOutlined style={{ color: ThemeConfig.colors.text }} />
            )}
          </Button>
        </Menu.Item>
        <SubMenu
          key="mylists"
          icon={<UnorderedListOutlined />}
          title="My lists"
        >
          <Menu.Item key="/collections">
            <Link to="/collections">
              {/* <HomeOutlined /> */}
              <span>All Collections</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="listplants"
            icon={<FontAwesomeIcon name="tree" sidebar />}
            title="Plants"
          >
            {plantCollections &&
              plantCollections.map((s: any) => (
                <Menu.Item key={`pm-${s.id}`}>
                  <Link to={`/collections/${s.id}`}>
                    {/* <HomeOutlined /> */}
                    <span>{capitalize(s.attributes?.name)}</span>
                  </Link>
                </Menu.Item>
              ))}
            <Menu.Item
              key="newPlant"
              icon={<PlusOutlined />}
              onClick={() => setShowAddPlant(true)}
            >
              New plant list
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="listwildlife"
            icon={<FontAwesomeIcon name="paw" sidebar />}
            title="Wildlife"
          >
            {animalCollections &&
              animalCollections.map((s: any) => (
                <Menu.Item key={`am-${s.id}`}>
                  <Link to={`/collections/${s.id}`}>
                    {/* <HomeOutlined /> */}
                    <span>{capitalize(s.attributes?.name)}</span>
                  </Link>
                </Menu.Item>
              ))}
            <Menu.Item
              key="newAnimal"
              icon={<PlusOutlined />}
              onClick={() => setShowAddAnimal(true)}
            >
              New Wildlife list
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <Menu.Item key="/newList">
          <Link
            to="/collections/start_new_list"
            onClick={(e) => {
              e.preventDefault();
              setShowAddcoll(true);
            }}
          >
            <AppstoreAddOutlined />
            <span>Start a new list</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/newListExport">
          <Link
            to="/collections/exportCollection"
            onClick={(e) => {
              e.preventDefault();
              setShowExportAList(true);
            }}
          >
            <ExportOutlined />
            <span>Export a list</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/newListImport">
          <Link
            to="/collections/upload_excel_file"
            onClick={(e) => {
              e.preventDefault();
              setShowImportAList(true);
            }}
          >
            <ImportOutlined />
            <span>Import a list</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/invite">
          <Link
            to="/invite"
            onClick={(e) => {
              e.preventDefault();
              setInviteModalOpen(true);
            }}
          >
            <UserAddOutlined />
            <span>Invite a user</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/invitecollaborate">
          <Link
            to="/invitecollaborate"
            onClick={(e) => {
              e.preventDefault();
              setCollaborateModalOpen(true);
            }}
          >
            <UserAddOutlined />
            <span>Invite a user to collaborate</span>
          </Link>
        </Menu.Item>
        {/* {menu &&
          menu.map((singleMenu, index) => {
            return (
              <Menu.Item key={singleMenu.path}>
                <Link
                  to={singleMenu.path}
                  {...(singleMenu.onClick
                    ? { onClick: singleMenu.onClick }
                    : {})}
                >
                  {singleMenu.icon}
                  <span>{singleMenu.title}</span>
                </Link>
              </Menu.Item>
            );
          })} */}
      </Menu>
      <InviteAUserModal
        isOpen={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <AddCollectionModal
        onClose={() => {
          setShowAddPlant(false);
        }}
        type="plant"
        visible={showAddPlant}
      />
      {collaborateModalOpen && (
        <CollaborateCollectionModal
          isOpen={collaborateModalOpen}
          onClose={(type) => {
            if (type === "success") {
              // refetch && refetch();
            }
            setCollaborateModalOpen(false);
            // setCollaborateCollection(null);
          }}
          plantCollections={plantCollections}
          animalCollections={animalCollections}
          // collection={}
        />
      )}
      <AddCollectionModal
        onClose={() => {
          setShowAddAnimal(false);
        }}
        type="animal"
        visible={showAddAnimal}
      />
      <UploadCollectionFileModal
        onClose={() => {
          setShowImportAList(false);
        }}
        visible={showImportAList}
      />
      <AddCollectionModal
        onClose={() => {
          setShowAddcoll(false);
        }}
        // type="animal"
        visible={showAddColl}
      />
      <ExportAListModal
        plantCollections={plantCollections}
        animalCollections={animalCollections}
        isOpen={showExportAList}
        onClose={(type) => {
          if (type === "success") {
            // refetch && refetch();
          }
          setShowExportAList(false);
          // setCollaborateCollection(null);
        }}
      />
    </StyledSidebar>
  );
};

export default Sidebar;
