import React from 'react';
import {  Layout as AntdLayout} from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import ReConfirmForm from 'components/Login/ReConfirmForm';
// import { ThemeConfig } from 'theme';

interface Props {}

const ReconfirmView = (props: Props) => {
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <ReConfirmForm />
    </AntdLayout>
  );
};

export default ReconfirmView;
