import React, { useEffect, useState } from "react";
import moment from "moment";
import { get } from 'lodash';
import {
  Table,
  Tag,
  Button,
  Row,
  Select,
  Space,
  Form,
  Input,
  Col,
  notification,
  Empty,
  Typography,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import styled from "styled-components";
import DropdownActions from "../DropdownActions/DropdownActions";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import FontAwesomeIcon from "components/Common/FontAwesomeIcon";
import { ThemeConfig } from "theme";
import { TableDarkHeader } from "components/Common/TableStyles";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  EJEPSON_COLUMNS,
  ENTERPRISE_COLUMNS_HEADERS,
  // NameColumn,
  NameInnerColumnKeys,
  PLANT_COMMON_COLUMNS,
  PLANT_COMMON_COLUMNS_HEADERS,
} from "./ejepson_table";
import { Resizable } from "react-resizable";
import {
  WILDLIFE_COLUMNS,
  WILDLIFE_COMMON_COLUMNS,
  WILDLIFE_COMMON_COLUMNS_HEADERS,
} from "./wild_life_table";
import { refresh, titleCase, truncateWithEllipsis } from "helper";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SelectColumn from "./SelectColumn";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import Api from "api";
import CollectionErrorReviewModal from "components/Models/CollectionErrorReviewModal";
import {
  addCollectionItem,
  addItemNote,
  deleteCollection,
  deleteCollectionItem,
} from "api/helper";
import { useHistory } from "react-router-dom";
import {
  animalDatabases,
  arrangeByOptions,
  extraDefaultColumns,
  hideColumns,
  searchOptions,
} from "./constants";
import SearchInput from "components/Common/SearchInput";
import CloneCollectionModal from "components/Models/CloneCollectionModal";
import ErrorResult from "components/Common/ErrorResult";
import ItemNote from "./ItemNote";
import config from "config";
import { useUser } from "helper/UserContext";
import CollectionDownloadModal from "components/Models/CollectionDownloadModal";
import { getTableScroll } from "helper/tableUtils";
const { Paragraph } = Typography;

interface Props {
  type?: string;
}

const { Option } = Select;
const CollectionItemsTableWrapper = styled.div`
  .ant-table-cell {
    text-align: center;
  }
  .collectionColumnButton .ant-select-selection-item {
    display: none !important;
  }
  .collectionColumnButton .ant-select-selector::before {
    position: absolute;
    content: "Columns";
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    padding-left: 10px;
    align-items: center;
  }
  .collectionColumnButton .ant-select-selector {
    background: ${ThemeConfig.colors.skyBlue};
    color: #fff;
  }
  .ant-table-thead > tr > th {
    padding: 5px !important;
  }
`;

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  // console.log('props are', props, width);
  if (!width) {
    // console.log("yoyo are", props);
    // return null
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      // style={{maxWidth:}}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
const emptyRows = [[], [], [], [], [], [], [], []];
const alphaSorter = (a, b) =>
                a !== b
                  ? a < b
                    ? -1
                    : 1
                  : 0;
const CollectionItemsTable = ({}: Props) => {
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [defaultSort, setDefaultSort] = useState<any>(null);
  const { user } = useUser();
  const [tableSettings, setTableSettings] = useState<any>({
    inputPlantIdName: "",
    term: "",
    showSelectColumns: false,
    isToggleOn: true,
    defaultSorted: [
      {
        id: "family_name",
        desc: false,
      },
    ],
  });
  const { collectionId } = useParams();
  const [arrangeBy, setArrangeBy] = useState<any>("family");
  const [database, setDatabase] = useState<any>("all");
  const [searchBy, setSearchBy] = useState<any>("all");
  const [excludeIds, setExcludeIds] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [autoCompleteItems, setAutoCompleteItems] = useState<any>([]);
  const [tableData, setTableData] = useState<any>(emptyRows);
  const [items, setItems] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [collection, setCollection] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  
  const location = useLocation();
  const handleDeleteCollectionItem = async (id) => {
    setTableLoading(true);
    //  console.log('deleting', id);
    //  return;
    const delRes = await deleteCollectionItem(collection.id, id, {
      type: collection.type,
    });
    setTableLoading(false);
    if (delRes && delRes.error) {
      notification.error({
        message: "Delete Failed",
        description: delRes.error,
      });
      // setLoading(false);
    } else {
      notification.success({
        message: "Deleted",
        description: delRes?.data?.messages[0] || "Success",
      });
      //  refetch && refetch();
      fetchCollection();
      //  window.location.href = "/collections";
      // refresh();
      // window.location.reload();
      // history.push("/collections");
    }
  };
  const initializeColumns = () => {
    if(columns && columns.length) {
      return;
    }
    const filteredColumns = collection.column_names
      .filter((s) => ![...NameInnerColumnKeys].includes(s.name))
      .map((col) => {
        return {
          title: col.export_name,
          dataIndex: col.name,
          sorter: true,
          show: !hideColumns.includes(col.name),
        };
      });
      var _columns: any = [];
      _columns = [
        filteredColumns[0],
        filteredColumns[1],
        {
          title: "Name",
          sorter: false,
          show: true,
          children: [
            {
              title: "Family",
              headerClassName: "family-header",
              dataIndex: "family",
              key: "family",
              className: "centered capitalize",
              // sorter: true,
              width: 100,
              sorter: alphaSorter,
              sortOrder:
                defaultSort &&
                defaultSort.columnKey === "family" &&
                defaultSort.order,
              ellipsis: true,
              show: true,
            },
            {
              title: "Genus",
              headerClassName: "genus-header",
              dataIndex: "genus",
              key: "genus",
              className: "centered capitalize",
              width: 100,
              sorter: alphaSorter,
              sortOrder:
                defaultSort &&
                defaultSort.columnKey === "genus" &&
                defaultSort.order,
              ellipsis: true,
              show: true,
            },
            {
              title: "species",
              dataIndex: "species",
              key: "species",
              className: "centered",
              width: 100,
              sorter: alphaSorter,
              sortOrder:
                defaultSort &&
                defaultSort.columnKey === "species" &&
                defaultSort.order,
              ellipsis: true,
              show: true,
            },
            {
              title: "Variety/Subspecies",
              dataIndex: "variety",
              key: "variety",
              className: "centered",
              width: 200,
              sorter: alphaSorter,
              sortOrder:
                defaultSort &&
                defaultSort.columnKey === "variety" &&
                defaultSort.order,
              ellipsis: true,
              show: true,
            },
          ],
        },
        ...filteredColumns.slice(2),
        {
          title: "Note",
          // sorter: true,
          width: 200,
          show: false,
          dataIndex: "note",
          render: (a, r) => {
            return (
              <ItemNote
                itemId={r.item_id}
                note={a}
                type={collection.type}
                collectionId={collection.id}
                setTableLoading={setTableLoading}
              />
            );
          },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          show: true,
          className: "centered",
          render: (a, r) => {
            if (!r.item_id) {
              return;
            }
            // <span className="label label-danger link" onClick={() => {}}>
            //   <i className="fa fa-trash"></i> Delete
            // </span>
            // <Popconfirm
            //   placement="top"
            //   title={"Are you sure you wish to delete this collection?"}
            //   onConfirm={() => handleDeleteCollection(record.id)}
            //   okText="Yes"
            //   cancelText="No"
            // >
            return (
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                size="small"
                onClick={(record: any) => handleDeleteCollectionItem(r.item_id)}
              >
                Delete
              </Button>
            );
            // </Popconfirm>
          },
        },
      ];
      setColumns(
        _columns.map((s) => ({
          ...s,
          width: s.width ? s.width : 150,
          ellipsis: true,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: s.width ? s.width : 150,
              }
            }
          },         
        }))
      );
  }

  const initializeData = () => {
    initializeColumns();
    setTableData(
      collection.items && collection.items.length > 0
        ? [...collection.items]
        : emptyRows
    );
    setItems(
      collection.items && collection.items.length > 0 ? collection.items : []
    );
    setExcludeIds(collection.items.map((s) => s.original_id));
    // setAutoCompleteItems([]);
    setLoading(false);
  };
  const fetchCollection = async () => {
    setTableLoading(true);
    try {
      const { data } = await Api.get(`/collections/${collectionId}`, {});
      if(data.data && data.data.is_user_upload && !data.data.mapped) {
        notification.warning({
          message: 'Action required',
          description: "You have not completed mapping for upload collection."
        })
        history.push(`/collections/${data.data.id}/save_mapped_columns`);
      } else if (data) {
        setCollection(data);
        if (!data.error_fixed) {
          setErrorModalOpen(true);
        }
      }
    } catch (e: any) {
      console.log("herereee", e);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch Colletion";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
    setTableLoading(false);
    setLoading(false);
  };
  useEffect(() => {
    setColumns([]);
    fetchCollection();
  }, [collectionId]);
  useEffect(() => {
    if (collection) {
      initializeData();
    }
    // setLoading(false);
  }, [collection]);
  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width > 100 ? size.width : 100,
      };
      // check if children //
      if(nextColumns[index].children){
        const diffWidth = size.width - columns[index].width;
        const diffPerChildWidth = Math.floor(diffWidth / nextColumns[index].children.length);
        const childHeaders = [...nextColumns[index].children]
        for (let k = 0; k < childHeaders.length; k++) {
          childHeaders[k].width = childHeaders[k].width + diffPerChildWidth
        }
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width > 100 ? size.width : 100,
          children: [...childHeaders],
        };
      }
      // end of check children //
      setColumns(nextColumns);
    };
  const handleColumnsChange = (values) => {
    const modifiedColumns = [...columns].map((s, i) => ({
      ...s,
      show: values.find((x) => x.value === i),
    }));
    setColumns(modifiedColumns);
  };
  const finalColumns = columns
    .map((col, index) => {
      const _extras:any = {};
      if(col.dataIndex === 'synonyms') {
        _extras.render = (tt) => <Tooltip title={tt}>{truncateWithEllipsis(tt, 60)}</Tooltip>;
      }
      return {
        ...col,
        sorter:
          col.sorter &&
          (typeof col.sorter === "function" ? col.sorter : alphaSorter),
        ..._extras,
        sortOrder:
          defaultSort &&
          defaultSort.columnKey === col.dataIndex &&
          defaultSort.order,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      };
    })
    .filter((s) => s.show);
  const screens = useBreakpoint();
  const onCollectionNameChange = async (collectionName) => {
    setLoading(true);
    // return;
    try {
      const { data } = await Api.put(`/collections/${collection.id}`, {
        name: collectionName,
      });
      notification.success({
        message: "Collection Renamed",
        description: "d",
      });
      fetchCollection();
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to map columns";
      notification.error({
        message: "Failed",
        description: errorMsg,
      });
    }
  };
  const handleDeleteCollection = async () => {
    const delRes = await deleteCollection(collection.id);
    if (delRes && delRes.error) {
      notification.error({
        message: "Delete Failed",
        description: delRes.error,
      });
    } else {
      notification.success({
        message: "Deleted",
        description: delRes.data || "Success",
      });
      window.location.href = '/collections';
      // history.push("/collections");
    }
  };
  const handleItemAdd = async (record_id) => {
    const { data, error } = await addCollectionItem(collection.id, {
      record_id,
      type: collection.type,
    });
    // TODO: show error here maybe
    fetchCollection();
  };
  const handleArrangeByChange = (v) => {
    setDefaultSort({
      order: "descend",
      columnKey: v,
    });
    setArrangeBy(v);
  };
  const downloadFile = async () => {
    try {
      if (user && user.status === "free" && !user.one_time_purchase) {
        setDownloadModalOpen(true);
        return;
      }
      // return;
      const res = await Api.get(
        `/collections/${collection.id}/download?type=xls`,
        {},
        { responseType: "blob" }
      );
      if (res && res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${collection.name || "collection"}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        notification.error({
          message: "Download failed",
          description: "Failed to download excel",
        });
      }
    } catch(e: any) {
      notification.error({
        message: "Download failed",
        description:  "Failed to download file",
      });
    }
  };
  useEffect(() => {
    if(collection) {
      initializeColumns();
    }
  }, [defaultSort])
  
  // console.log(screens);
  if (loading) {
    return <LoadingSpinnerView size={80} />;
  }
  if (!collection) {
    return <ErrorResult />;
  }
  // if(!collection) {
  //   return <Empty />;
  // }
  return (
    <CollectionItemsTableWrapper>
      {/* <TableDarkHeader>{collection.type}</TableDarkHeader> */}
      {/* <TableDarkHeader>
        {type === "plant" ? "Plant" : "Wildlife"}
      </TableDarkHeader> */}
      <Row>
        <Paragraph
          editable={{
            onChange: onCollectionNameChange,
            tooltip: "click to edit text",
            triggerType: ["text", "icon"],
          }}
        >
          {titleCase(collection.name)}
        </Paragraph>
      </Row>
      <Row style={{ paddingBottom: 10 }}>
        <Col lg={{ span: 5 }} span={24}>
          <label>&nbsp;</label>
          {/* <Input.Search></Input.Search> */}
          <SearchInput
            type={collection.type}
            collectionId={collection.id}
            placeholder="Search..."
            onSelect={handleItemAdd}
            searchKey={searchBy}
            database={database}
            excludeIds={excludeIds}
          />
        </Col>
        <Col lg={10} span={24}>
          <Row>
            <Col xs={8}>
              <label>Search By</label>
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue={searchBy}
                onChange={(v) => {
                  setSearchBy(v);
                }}
                style={{ width: "100%" }}
                // onChange={handleChange}
              >
                {searchOptions.map((s) => (
                  <Option key={s.value} value={s.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={8}>
              <label>Arrange By</label>
              <Select
                dropdownMatchSelectWidth={false}
                value={arrangeBy}
                onChange={handleArrangeByChange}
                style={{ width: "100%" }}
                // style={{ width: 150 }}
                // onChange={handleChange}
              >
                {arrangeByOptions.map((s) => (
                  <Option key={s.value} value={s.value}>
                    {s.label}
                  </Option>
                ))}
              </Select>
            </Col>
            {collection.type === "animal" && (
              <Col xs={8}>
                <label>Database</label>
                <Select
                  dropdownMatchSelectWidth={false}
                  defaultValue={database}
                  onChange={(v) => {
                    setDatabase(v);
                  }}
                  style={{ width: "100%" }}
                  // style={{ width: 150 }}
                  // onChange={handleChange}
                >
                  {animalDatabases.map((s) => (
                    <Option key={s.value} value={s.value}>
                      {s.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        </Col>
        <Col lg={9} xs={24}>
          <Row style={{ justifyContent: "space-between" }}>
            <Col>
              <label style={{ display: "flex" }}>&nbsp;</label>
              <SelectColumn onChange={handleColumnsChange} columns={columns} />
            </Col>
            <Col>
              <label style={{ display: "flex" }}>&nbsp;</label>
              <Button onClick={downloadFile}>Download Excel File</Button>
            </Col>
            {!collection.error_fixed && !!collection.error_count && (
              <Col>
                <label style={{ display: "flex" }}>&nbsp;</label>
                <Button onClick={() => setErrorModalOpen(true)}>
                  Review Import now
                </Button>
                <CollectionErrorReviewModal
                  isOpen={errorModalOpen}
                  onClose={(action) => {
                    if (action === "refetch") {
                      fetchCollection();
                    }
                    setErrorModalOpen(false);
                  }}
                  collection={collection}
                />
              </Col>
            )}
            {downloadModalOpen && (
              <CollectionDownloadModal
                isOpen={downloadModalOpen}
                onClose={(action) => {
                  //   if (action === "refetch") {
                  //     fetchCollection();
                  //   }
                  //   setErrorModalOpen(false);
                  setDownloadModalOpen(false);
                }}
                collectionId={collection.id}
                // collection={collection}
              />
            )}
            <Col>
              <label style={{ display: "flex" }}>&nbsp;</label>
              <Popconfirm
                placement="topLeft"
                title={"Are you sure you wish to delete this collection?"}
                onConfirm={handleDeleteCollection}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: "red" }}
                  icon={<DeleteOutlined />}
                >
                  {!screens.xs ? "Delete" : ""}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        onChange={(pgn, flt, srt: any, act: any) => {
          if (act && act.action === "sort") {
            if (srt && ["genus", "family", "created_at"].includes(srt.field)) {
              setArrangeBy(srt.order ? srt.field : null);
              setDefaultSort(srt);
            } else {
              setDefaultSort({ ...srt, columnKey: srt.field });
            }
          }
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        // scroll={{ x: true }}
        scroll={{ x: "max-content", y: getTableScroll({ extraHeight: null, id: null }) }}
        loading={loading || tableLoading}
        dataSource={tableData}
        // onChange={handleTableChange}
        //   pagination={{
        //     pageSize: pageLimit,
        //     total: pagination.total,
        //     showTotal: (total, range) =>
        //       `${range[0]}-${range[1]} of ${total} items`,
        //     // onChange: handlePageChange,
        //   }}
        pagination={{
          position: ["bottomCenter"],
          // pageSize: 50,
        }}
        // style={{ minHeight: 800 }}
        rowKey={(record, i) => record.item_id || record.id || `t${i}`}
        columns={finalColumns}
      />
    </CollectionItemsTableWrapper>
  );
};

export default CollectionItemsTable;