import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(AntdLayout)`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

export const StyledContent = styled(AntdLayout.Content)`
    padding: 10px;
`;