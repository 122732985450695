import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeConfig } from 'theme';
import {
  Form,
  Input,
  Button,
  Layout as AntdLayout,
  Col,
  Row,
  Select,
  message,
  Result,
  Typography,
} from "antd";
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import Api from 'api';
import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import { typeMapping } from 'helper/securityAnswerValidator';

const { Option } = Select;

const MainViewWrapper = styled.div`
  overflow: scroll;
  width: 100vw;
  margin-top: 120px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
  }
  .ant-form-item-control {
    padding: 5px;
  }
  .ant-form-item {
    // margin-bottom: 15px;
  }
  .ant-form-item-label {
    // padding: 0px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const STAGES = {
  "RESET_QUESTION_STAGE": "RESET_QUESTION_STAGE",
  "OTP_STAGE": "OTP_STAGE",
}
const TITLE = {
  [STAGES.RESET_QUESTION_STAGE]: "RESET SECURITY QUESTIONS",
  [STAGES.OTP_STAGE]: "ENTER OTP NUMBER",
}

interface Props { }

const ResetSecurityQuestions = (props: Props) => {
  const [form] = Form.useForm();
  const [otpform] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [verifyToken, setVerifyToken] = useState<string>();
  const [securityDetails, setSecurityDetails] = useState<{}>();
  const [result, setResult] = useState<string>();
  const [stage, setStage] = useState<string>(STAGES.RESET_QUESTION_STAGE);
  const location = useLocation();

  useEffect(() => {
    const queries = qs.parse(location.search);
    if (!queries || !queries.token) {
      message.error({ content: "Invalid verification url", duration: 5 });
    } else {
      setVerifyToken(queries.token as string);
    }
  }, [location.search]);

  const handleSubmit = async (values) => {
    setLoading(true);
    setSecurityDetails({ ...values });
    try {
      const { data } = await Api.get("/auth/send_otp", { token: verifyToken });
      if (data) {
        setStage(STAGES.OTP_STAGE)
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to get otp. Try again later";
      console.log(e && e.response && e.response.data && e.response.data.messages && e.response.data.messages[0]);
      message.error({ content: errorMsg, duration: 3 });
    }
  };

  const handleOTPSubmit = async (values) => {
    try {
      const { data } = await Api.post("/auth/reset_security_questions", { ...values, ...securityDetails, token: verifyToken });
      setResult(data.message.join(". "));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Register. Try again later";
      console.log(e && e.response && e.response.data && e.response.data.messages && e.response.data.messages[0]);
      message.error({ content: errorMsg, duration: 3 });
    }

  }



  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  if (result && result.length) {
    return (
      <AntdLayout>
        <HeaderNoAuth />
        <MainViewWrapper>
          <Result
            status="success"
            title="Security Change Success!"
            subTitle={result}
            extra={[
              <Button type="primary" key="console">
                <a href="/login">SignIn</a>
              </Button>,
            ]}
          />
        </MainViewWrapper>
      </AntdLayout>
    );
  }
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <MainViewWrapper>
        <FormWrapper>
          <Col lg={{ span: 8, offset: 8 }}>
            <h4>{TITLE[stage]}</h4>
            {stage === STAGES.RESET_QUESTION_STAGE && (
              <Form
                {...layout}
                form={form}
                autoComplete="off"
                name="login"
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Question 1"
                      name="security_question1"
                      labelAlign="left"
                      rules={[{ required: true, message: "Enter question 1" }]}
                    >
                      <Select
                        placeholder="What was the house number and street name you lived in as a child?"

                      >
                        <Option value="What was the house number and street name you lived in as a child?">
                          What was the house number and street name you lived in
                          as a child?
                        </Option>
                        <Option value="What were the last four digits of your childhood telephone number?">
                          What were the last four digits of your childhood
                          telephone number?
                        </Option>
                        <Option value="What primary school did you attend?">
                          What primary school did you attend?
                        </Option>
                        <Option value="In what town or city was your first full time job?">
                          In what town or city was your first full time job?
                        </Option>
                        <Option value="In what town or city did you meet your spouse/partner?">
                          In what town or city did you meet your spouse/partner?
                        </Option>
                        <Option value="What is the middle name of your oldest child?">
                          What is the middle name of your oldest child?
                        </Option>
                        <Option value="What are the last five digits of your driver's licence number?">
                          What are the last five digits of your driver's licence
                          number?
                        </Option>
                        <Option value="What is your grandmother's (on your mother's side) maiden name?">
                          What is your grandmother's (on your mother's side)
                          maiden name?
                        </Option>
                        <Option value="What is your spouse or partner's mother's maiden name?">
                          What is your spouse or partner's mother's maiden name?
                        </Option>
                        <Option value="In what town or city did your mother and father meet?">
                          In what town or city did your mother and father meet?
                        </Option>
                        <Option value="What time of the day were you born? (hh:mm)">
                          What time of the day were you born? (hh:mm)
                        </Option>
                        <Option value="What time of the day was your first child born? (hh:mm)">
                          What time of the day was your first child born? (hh:mm)
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Answer 1"
                      name="answer1"
                      labelAlign="left"
                      dependencies={["security_question1"]}
                      rules={[
                        {
                          required: true,
                          message: "Enter answer 1",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const questionValue =
                              getFieldValue("security_question1");

                            if (!questionValue || !value) {
                              return Promise.resolve();
                            }
                            const validationRegex =
                              typeMapping[questionValue].regex || false;
                            const validationTruth =
                              typeMapping[questionValue].truth;
                            if (!validationRegex) {
                              return Promise.resolve();
                            }
                            if (
                              validationTruth
                                ? validationRegex.test(value)
                                : !validationRegex.test(value)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(typeMapping[questionValue].message)
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Answer 1"

                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Question 2"
                      name="security_question2"
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Enter question 2" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const questionValue =
                              getFieldValue("security_question1");

                            if (!questionValue || !value) {
                              return Promise.resolve();
                            }
                            if (questionValue === value) {
                              return Promise.reject(new Error('Question 2 cannot be same as Question 1'))
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select
                        placeholder="What was the house number and street name you lived in as a child?"

                      >
                        <Option value="What was the house number and street name you lived in as a child?">
                          What was the house number and street name you lived in
                          as a child?
                        </Option>
                        <Option value="What were the last four digits of your childhood telephone number?">
                          What were the last four digits of your childhood
                          telephone number?
                        </Option>
                        <Option value="What primary school did you attend?">
                          What primary school did you attend?
                        </Option>
                        <Option value="In what town or city was your first full time job?">
                          In what town or city was your first full time job?
                        </Option>
                        <Option value="In what town or city did you meet your spouse/partner?">
                          In what town or city did you meet your spouse/partner?
                        </Option>
                        <Option value="What is the middle name of your oldest child?">
                          What is the middle name of your oldest child?
                        </Option>
                        <Option value="What are the last five digits of your driver's licence number?">
                          What are the last five digits of your driver's licence
                          number?
                        </Option>
                        <Option value="What is your grandmother's (on your mother's side) maiden name?">
                          What is your grandmother's (on your mother's side)
                          maiden name?
                        </Option>
                        <Option value="What is your spouse or partner's mother's maiden name?">
                          What is your spouse or partner's mother's maiden name?
                        </Option>
                        <Option value="In what town or city did your mother and father meet?">
                          In what town or city did your mother and father meet?
                        </Option>
                        <Option value="What time of the day were you born? (hh:mm)">
                          What time of the day were you born? (hh:mm)
                        </Option>
                        <Option value="What time of the day was your first child born? (hh:mm)">
                          What time of the day was your first child born? (hh:mm)
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Answer 2"
                      name="answer2"
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Enter answer 2" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const questionValue =
                              getFieldValue("security_question2");

                            if (!questionValue || !value) {
                              return Promise.resolve();
                            }
                            const validationRegex =
                              typeMapping[questionValue].regex || false;
                            const validationTruth =
                              typeMapping[questionValue].truth;
                            if (!validationRegex) {
                              return Promise.resolve();
                            }
                            if (
                              validationTruth
                                ? validationRegex.test(value)
                                : !validationRegex.test(value)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(typeMapping[questionValue].message)
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Answer 2"

                      />
                    </Form.Item>
                  </Col>
                </Row>


                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{
                      width: "100%",
                      background: ThemeConfig.colors.skyBlue,
                      marginTop: 10,
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
            {stage === STAGES.OTP_STAGE && (
              <Form
                {...layout}
                form={otpform}
                autoComplete="off"
                name="otp"
                layout="vertical"
                onFinish={handleOTPSubmit}
              >
                <Form.Item
                  // label="OTP"
                  name="OTP"
                  labelAlign="left"
                  rules={[
                    { required: true, message: "Enter your OTP Number" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {

                        const validationRegex = /^\d{6}$/
                        if (
                          validationRegex.test(value)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter valid six digit OTP")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="OTP"
                  />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{
                      width: "100%",
                      background: ThemeConfig.colors.skyBlue,
                      marginTop: 10,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography.Text type='secondary' style={{ textAlign: 'center', marginTop: 5 }}>
                    A message with a verification code has been sent to
                    your email. Enter the code to continue.
                  </Typography.Text>
                </div>
              </Form>
            )}
          </Col>
        </FormWrapper>
      </MainViewWrapper>
    </AntdLayout>
  );
};

export default ResetSecurityQuestions;
