import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Col,
  message,
  Row
} from "antd";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import Api from "api";
import { ThemeConfig } from "theme";
import { useUser } from "helper/UserContext";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";

const LoginWrapper = styled.div`
  margin-top: 70px;
  min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;


interface Props {
  // resetToken: any;
}

const SettingsForm = (props: Props) => {
  const [form] = Form.useForm();
  const {user, setUser} = useUser();
  const [loading, setLoading] = useState<boolean>(false);
   const handleProfileData = (pData) => {
     setUser({ ...pData });
   };
   
  const handleSettingsUpdate = async (values) => {
    setLoading(true);
    try {
      delete values.email;
      const { data } = await Api.post("/account/profile", values);
      setLoading(false);
      if (data && data.data) {
         message.success(data.message)
         handleProfileData({
           id: data.data.id,
           ...data.data.attributes,
         });
      } else {
        message.error('Something went wrong');
      }
    } catch (e: any) {
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to change settings";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  if (loading || !user) return <LoadingSpinnerView size={80} />;
  return (
    <LoginWrapper>
      <FormWrapper>
        <Col lg={{ span: 8, offset: 8 }}>
          <h4 className="mb-3" style={{ color: "#494848" }}>
            Settings
          </h4>
          <Form
            {...layout}
            initialValues={{...user}}
            form={form}
            onFinish={handleSettingsUpdate}
            name="settings"
            layout="vertical"
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  labelAlign="left"
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Enter a valid email" },
                  ]}
                >
                  <Input
                    disabled
                    prefix={
                      <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  labelAlign="left"
                  rules={[
                    { required: true, message: "Enter your Name" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var validationRegex = /[^a-z A-Z]/;
                        if (!value || !validationRegex.test(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("Please enter valid name")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Name"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    // onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                {user && (user.status === 'free_trial' || user.status === 'free' ) && <Form.Item label="Coupon" name="coupon" labelAlign="left">
                  <Input
                    placeholder="Coupon"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    // onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>}
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit"
                // onClick={login}
                loading={loading}
              >
                Update
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: ThemeConfig.colors.grey,
              }}
            >
              <a className="greyLink" href="/user/change_password">
                Change Password
              </a>
            </div>
          </Form>
        </Col>
      </FormWrapper>
    </LoginWrapper>
  );
};

export default SettingsForm;
