import { Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeConfig } from "theme";

interface Props {
  onChange: any;
  columns: any;
}

const { Option } = Select;
const SelectColumnWrapper = styled.div`
  .collectionColumnButton .ant-select-selector {
    background: ${ThemeConfig.colors.skyBlue};
    color: #fff;
  }
`;
const SelectColumn = ({ onChange, columns } : Props) => {
    if(!columns.length) {
        return null;
    }
    return (
      <SelectColumnWrapper>
        <Select
          dropdownStyle={{ width: 200 }}
          className="collectionColumnButton"
          // placeholder="Columns"
          labelInValue
          showArrow
          dropdownMatchSelectWidth={false}
          showSearch={false}
          // tagRender={() => null as any}
          mode="multiple"
          defaultValue={columns
            .map((s, i) => ({ value: i, label: s.title, show: s.show }))
            .filter((s) => s.show)}
          style={{ width: 80, background: "red" }}
          onChange={onChange}
        >
          {columns
            .filter((s) => s.title !== "Actions")
            .map((s, i) => (
              <Option value={i} key={`sdf${i}`}>
                {s.title}
              </Option>
            ))}
        </Select>
      </SelectColumnWrapper>
    );
}

export default SelectColumn;