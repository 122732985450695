import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, Col, message, Row, Card, Avatar, notification } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  MailOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Api from "api";
import { capitalize } from 'lodash';
import { ThemeConfig } from "theme";
import { useUser } from "helper/UserContext";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import {
  getCheckoutSession,
  getCustomerPortalSession,
  getSubscriptionProducts,
} from "api/helper";
import { useStripe } from "@stripe/react-stripe-js";
import moment from "moment";

const LoginWrapper = styled.div`
  margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  color: ${ThemeConfig.colors.text};
  border-radius: 20px;
  background-color: ${ThemeConfig.colors.darkBlue};
  text-align: center;
  padding: 20px 0 20px 0;
  h3,
  h2 {
    color: ${ThemeConfig.colors.text};
  }
  .productListButtons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    button {
      margin: 10px;
      border-radius: 24px;
      font-size: 17px;
      font-weight: 500;
    }
  }
  .priceButtonEnterprise {
    background-color: ${ThemeConfig.colors.lightGreen};
    border-color: ${ThemeConfig.colors.lightGreen};
  }
  .downgradeTitle {
    margin: 0px;
    color: #494848;
    font-weight: 400;
    font-size: 16px;
  }
`;

interface Props {
  // resetToken: any;
  checkoutInfo?: any;
}
const BillingForm = (props: Props) => {
  const stripe = useStripe();
  const [products, setProducts] = useState<any>([]);
  const [portalSession, setPortalSession] = useState<any>(null);
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const handleProfileData = (pData) => {
    setUser({ ...pData });
  };
  const getProfile = async () => {
    try {
      const { data } = await Api.get("/account/profile", {});
      handleProfileData({
        id: data.data.id,
        ...data.data.attributes,
      });
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch Profile";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
    setLoading(false);
  };
  
  const initialize = async () => {
    const subscriptionProducts = await getSubscriptionProducts();
    const _portalSession = await getCustomerPortalSession();
    _portalSession && _portalSession.data && setPortalSession(_portalSession.data);
    if (subscriptionProducts.data) {
      setProducts(subscriptionProducts.data);
    }
};
  const handleProductclick = async (product) => {
    // return;
    try {
      const checkoutSession = await getCheckoutSession(
        product.prices[0].stripe_price_id
      );
      console.log('checkoutSession is', checkoutSession);
      if (
        checkoutSession &&
        checkoutSession.error &&
        checkoutSession.error.includes("active subscription")
      ) {
        notification.success({
          message: 'Active subscription exists',
          description: 'Redirecting to stripe to manage your subscription'
        });
        window.location.href = portalSession?.session_id?.url;
        return;
      }
        if (checkoutSession.data && checkoutSession.data.session_id) {
          setLoading(true);
          stripe?.redirectToCheckout({
            sessionId: checkoutSession.data.session_id,
          });
        } else {
          notification.error({
            message: "Purchase failed",
            description:
              (checkoutSession && checkoutSession.error) ||
              "Failed to generate checkout session",
          });
        }
    } catch(e: any) {
      setLoading(false);
      notification.error({
        message: "Purchase failed",
        description: e.toString(),
      });
    }
  };
  useEffect(() => {
    
    getProfile();
    initialize();
  }, []);
  if (loading || !user || !stripe) return <LoadingSpinnerView size={80} />;
  const isFree = user.status === "free" || user.status === "free_trial";
  const isProfessional = user.status === 'professional';
  // const isEnterprise = user.status === 'enterprise';
  return (
    <LoginWrapper>
      <Row>
        <Col lg={{ span: 12, offset: 6 }} span={24}>
          <FormWrapper>
            {(isFree || isProfessional) && <h2>Upgrade</h2>}
            <h2>
              Your current plan is{" "}
              <strong>
                {capitalize((user.status || "").split("_").join(" "))}
              </strong>
            </h2>
            {user.status === "free_trial" && (
              <h3>Days left : {user.left_trial_days || 0}</h3>
            )}
            {user.subscription_expire_at && (
              <h3>
                Expires at:{" "}
                {moment(user.subscription_expire_at).format("Do MMM YYYY")}
              </h3>
            )}
            {user.subscription_auto_renew_at && (
              <h3>
                AutoRenews at:{" "}
                {moment(user.subscription_auto_renew_at).format("Do MMM YYYY")}
              </h3>
            )}
            {portalSession && (
              <div>
                Click <a href={portalSession?.session_id?.url}>here </a>
                to manage your plan
              </div>
            )}
            <div className="productListButtons">
              {products
                .filter((s) => {
                  // if (isEnterprise) {
                  //   return false;
                  // }
                  // if (
                  //   (isProfessional || isEnterprise) &&
                  //   s.name === "Professional"
                  // ) {
                  //   return false;
                  // }
                  if(!['enterprise', 'professional'].includes(s.name && s.name.toLowerCase())) {
                    return false;
                  }
                  // if (isEnterprise && s.name === "Enterprise") {
                  //   return false;
                  // }
                  
                  if (s.active) {
                    return true;
                  }
                })
                .map((s) => (
                  <Button
                    // block
                    onClick={() => {
                      handleProductclick(s);
                    }}
                    key={s.name}
                    type="primary"
                    className={`priceButton${s.name}`}
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    {s.name} Plan @ ${(s.prices[0].amount / 100).toFixed(2)} per
                    month
                  </Button>
                ))}
            </div>

            {/* <p className="downgradeTitle">
              To downgrade your plan please contact{" "}
            </p>
            <a href="mailto:support@SpeciesListPro.com">
              support@SpeciesListPro.com
            </a> */}
          </FormWrapper>
        </Col>
      </Row>
    </LoginWrapper>
  );
};

export default BillingForm;
