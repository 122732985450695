import React from "react";
import { DashboardContainer } from "components/Container/Container";
import SettingsForm from "components/Login/SettingsForm";
function SettingsView() {
  return <DashboardContainer>
    <SettingsForm />
  </DashboardContainer>;
}

export default SettingsView;
