import React, { useState } from "react";
import { Modal, Button, Input, message, Alert, Form, Col, Row, notification, Select, Radio } from "antd";
import Api from "api";
import styled from "styled-components";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { ThemeConfig } from "theme";
import { checkUserCan } from "api/helper";
import { useUser } from "helper/UserContext";
import { capitalize } from 'lodash';
const InviteWrapper = styled.div`
  // margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const CollaborateCollectionModal = ({ isOpen, onClose, collection = null, plantCollections = [], animalCollections = [] }) => {
  const [form] = Form.useForm();
  const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<any>(false);
  const [selectedCollection, setSelectedCollection] = useState<any>(collection);
  const handleTicket = async () => {
    setLoading(true);
    if(!collection && !selectedCollection) {
      setLoading(false);
      return;
    }
    await form
      .validateFields()
      .then(async (values) => {
        try {
          const { data } = await Api.post("/collections/collaborate", {
            user_collection: selectedCollection.id,
            email: values.email,
            name: values.name,
          });
          setLoading(false);
          if (data && data.messages) {
            notification.success({
              message: "Invited",
              description: data.messages[0],
            });
            form.resetFields();
            onClose("success");
          }
        } catch (e: any) {
          console.log("error", e.response.data.messages[0]);
          setLoading(false);
          const errorMsg =
            (e &&
              e.response &&
              e.response.data &&
              e.response.data.messages &&
              e.response.data.messages[0]) ||
            "Failed to send invitation";
          notification.error({
            message: "Failed to invite",
            description: errorMsg
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setLoading(false);
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <Modal
      title="INVITE OTHER USERS TO COLLABORATE"
      style={{ textAlign: "center", fontWeight: "bold" }}
      visible={isOpen}
      onCancel={() => {
        onClose("cancel");
        form.resetFields();
      }}
      footer={
        checkUserCan(user, "collaborate") ? (
          [
            <Button
              key="back"
              onClick={() => {
                onClose("cancel");
                form.resetFields();
              }}
            >
              Close
            </Button>,
            <Button
              loading={loading}
              key="submit"
              type="primary"
              onClick={handleTicket}
            >
              Invite a user
            </Button>,
          ]
        ) : (
          <Row style={{ justifyContent: "center" }}>
            <Button
              key="back"
              type="text"
              onClick={() => {
                onClose("cancel");
              }}
            >
              No Thanks
            </Button>
          </Row>
        )
      }
    >
      <InviteWrapper>
        {checkUserCan(user, "collaborate") ? (
          <FormWrapper>
            <Col>
              <Form
                {...layout}
                // initialValues={{ ...user }}
                form={form}
                // onFinish={handleSettingsUpdate}
                name="resetPassword"
                layout="vertical"
                autoComplete="off"
              >
                <Row>
                  <Col span={24}>
                    {collection && (
                      <Form.Item
                        label="Collection"
                        name="collection"
                        labelAlign="left"
                        initialValue={
                          selectedCollection &&
                          selectedCollection.attributes &&
                          selectedCollection.attributes.name
                        }
                      >
                        <Input
                          disabled
                          prefix={
                            <MailOutlined
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Collection"
                        />
                      </Form.Item>
                    )}
                    <Col span={24}>
                      <Form.Item
                        label="Name"
                        name="name"
                        labelAlign="left"
                        rules={[
                          { required: true, message: "Enter your Name" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              var validationRegex = /[^a-z A-Z]/;
                              if (!value || !validationRegex.test(value)) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error("Please enter valid name")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          placeholder="Name"
                          prefix={
                            <UserOutlined
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          // onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                      {/* <Form.Item label="Coupon" name="coupon" labelAlign="left">
                  <Input
                    placeholder="Coupon"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    // onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item> */}
                    </Col>
                    <Form.Item
                      label="Email"
                      name="email"
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Email is required" },
                        { type: "email", message: "Enter a valid email" },
                      ]}
                    >
                      <Input
                        prefix={
                          <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Email"
                      />
                    </Form.Item>
                    {!collection && <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select collection type!",
                        },
                      ]}
                      style={{ textAlign: "left" }}
                      label="Collection Type"
                      name="type"
                    >
                      {/* <Input placeholder="Enter collection url" /> */}
                      <Radio.Group
                        options={["animal", "plant"]}
                        size="large"
                        optionType="button"
                        style={{ width: "100%", textTransform: "capitalize" }}
                        onChange={(e) => {
                          console.log(form.getFieldsValue());
                          setType(e.target.value);
                        }}
                        // value={value1}
                      />
                    </Form.Item>}
                    {!!type && (
                      <Form.Item
                        label={capitalize(type)}
                        name="merge_id"
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: "Target collection is required",
                          },
                        ]}
                      >
                        <Select
                          onChange={(ev) => {
                            console.log("changed", ev);
                            setSelectedCollection({
                              id: ev,
                            });
                          }}
                        >
                          {(type === "plant"
                            ? plantCollections
                            : animalCollections
                          ).map((s: any) => (
                            <Select.Option key={s.id} value={s.id}>
                              {s.attributes.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </FormWrapper>
        ) : (
          <Row style={{ justifyContent: "center" }}>
            <p
              className="packageDescription"
              style={{ fontSize: 20, fontWeight: 400, textAlign: "center" }}
            >
              The collaborate with others feature is only available to
              Enterprise users.
            </p>
            <Button type="primary" href="/user/billing">
              Upgrade
            </Button>
          </Row>
        )}
      </InviteWrapper>
    </Modal>
  );
};
export default CollaborateCollectionModal;
