import React, { useState } from "react";
import { DashboardContainer } from "components/Container/Container";
import BillingForm from "components/Login/BillingForm";
import { Alert } from "antd";

interface Props {
  checkoutInfo?: any;
}
function BillingView(props: Props) {
  const [checkoutInfo, setCheckoutInfo] = useState<any>(props.checkoutInfo);

  return (
    <DashboardContainer>
      {props.checkoutInfo && props.checkoutInfo.type === "success" && (
        <Alert
          type="success"
          message="Your purchase was successful"
          banner
          closable
        />
      )}
      {props.checkoutInfo && props.checkoutInfo.type === "cancel" && (
        <Alert
          type="error"
          message="Your checkout session was canceled"
          banner
          closable
        />
      )}
      <BillingForm checkoutInfo={props.checkoutInfo} />
    </DashboardContainer>
  );
}

export default BillingView;
