export const searchOptions = [
  { value: "all", label: "All" },
  { value: "common_names", label: "Common names" },
  { value: "scientific_names", label: "Scientific name" },
];

export const arrangeByOptions = [
  { value: "genus", label: "By Genus" },
  { value: "family", label: "By Family" },
  { value: "created_at", label: "In Order Entered" },
];
export const animalDatabases = [
      { value: 'butterflies', label: 'Butterflies' },
      { value: 'birds', label: 'Birds' },
      { value: 'reptiles', label: 'Reptiles' },
      { value: 'fish', label: 'Fish' },
      { value: 'mammals', label: 'Mammals' },
      { value: 'insects', label: 'Insects' },
      { value: 'all', label: 'All' },
    ];
export const hideColumns = [
  "plant_type",
  "distribution_outside_of_california",
  "ipc_listed_weed",
  "usace_wetland_status",
  "usace_wetland_name",
  "full_name",
  "unique_id",
  "forest_service_rank",
  "blm_rank",
  "habitat",
  "elevation_range",
  "flowering_time",
  "local_county_rank",
  "link_to_eflora_jepson",
  "cal_photo_link",
  "global_state_rank",
  "state_rank",
  "jepson_eflora_other_notes",
  "counties",
  "cnps_quads",
  "cnps_california_counties_and_islands",
  "animal_class",
  "animal_order",
  "animal_class",
  "animal_order",
  "common_type",
  "common_sub_type",
  "habit",
  "range",
  "host_plant",
  "global_rank",
  "state_rank",
  "federal_rank",
  "california_rank",
  "forest_service",
  "blm_rank",
  "other",
  "iucn_rank",
];
export const extraDefaultColumns = [
  {
  title: "Note",
  // sorter: true,
  show: true,
  dataIndex: 'note'
}
];