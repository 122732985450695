import React from 'react';
import { ThemeProvider } from 'styled-components';

export const ThemeConfig = {
  colors: {
    primary: "#00205B",
    darkBlue: "#0d2942",
    darkerBlue: "#002140",
    secondary: "#4EC3E0",
    skyBlue: "#2bb0e7",
    darkGrey: "#191919",
    lightGrey: "#cccccc",
    text: "#fff",
    placeholder: "#cccccc",
    dark: "rgb(8, 36, 60)",
    grey: "#8a8787",
    green: "#0fff65",
    lightGreen: "#5CB95B",
  },
  fonts: ["Open Sans", "Roboto"],
  fontSizes: {
    small: "0.75rem",
    medium: "1rem",
    large: "1.25rem",
  },
};

const Theme = ({ theme = {}, children }) => (
  <ThemeProvider
    theme={{
      colors: {
        ...ThemeConfig.colors,
      },
      fonts: ThemeConfig.fonts,
      fontSizes: ThemeConfig.fontSizes,
    }}
  >
    {children}
  </ThemeProvider>
);

export default Theme;
