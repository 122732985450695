import React, { useEffect, useState } from 'react';
import { StyledHeaderAuth } from "./HeaderStyles";
import { Avatar, Col, Row, Menu, message, Dropdown} from "antd";
import styled from "styled-components";
import { CaretDownFilled, DownOutlined, UserOutlined, } from '@ant-design/icons';
import logo from "../../../specieslistprologo.png";
import slp from "../../../assets/logo180.png";
import Api from 'api';
import { removeToken, removeUserData } from 'helper';
import { useUser } from 'helper/UserContext';
import { useHistory, Link } from "react-router-dom";
import NeedHelpModal from 'components/Models/NeedHelpModal';

const { SubMenu } = Menu;
interface Props {
  toggleSidebar: Function;
  collapse: boolean;
  isMobile: boolean;
}

const TopNavLink = styled.li`
  margin-right: 10px;
  margin-left: 10px;
  list-style: none;
  &:hover a {
    color: rgba(255, 255, 255, 0.75);
    border-top: 3px solid #0fff65;
  }
  a {
    display: block;
    font-weight: bold;
    border-top: 3px;
    border-color: rgba(111, 111, 111, 0.2) transparent transparent;
    border-style: solid;
    padding-left: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
`;


const HeaderAuth: React.FC<Props> = (props) => {
  const { user, setUser } = useUser();
  const [helpModalVisible, setHelpModalVisile] = useState<boolean>(false);
  const history = useHistory();
  const handleHelpModalClose = (type) => {
    setHelpModalVisile(false);
  }
  const handleNeedHelpPress = (e) => {
    e.preventDefault();
    setHelpModalVisile(true);
  }
  const handleLogOut = () => {
    removeToken();
    removeUserData();
    setUser(null);
    window.location.reload();
  };
  const handleProfileData = (pData) => {
    setUser(pData);
  }
   const getProfile = async () => {
    //  setLoading(true);
     try {
       const { data } = await Api.get("/account/profile", {});
       handleProfileData({
         id: data.data.id,
         ...data.data.attributes,
       });
     } catch (e: any) {
       const errorMsg =
         (e &&
           e.response &&
           e.response.data &&
           e.response.data.messages &&
           e.response.data.messages[0]) ||
         "Failed to Fetch Profile";
         console.log(errorMsg);
       message.error({ content: errorMsg, duration: 3 });
     }
   };
   const HeaderLeft = ({isMobile}) => {
     
      return (
        <Col
          sm={{ span: 4 }}
          lg={{ span: 4 }}
          style={{ height: "80px" }}
        >
          {isMobile &&
            (props.collapse ? (
              <>
                <img src={slp} alt="Logo" style={{ height: 30 }} />
                {/* <MenuUnfoldOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => props.toggleSidebar(!props.collapse)}
                /> */}
              </>
            ) : (
              <>
                <img src={slp} alt="Logo" style={{ height: 30 }} />
                {/* <MenuUnfoldOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => props.toggleSidebar(!props.collapse)}
                /> */}
              </>
            ))}
          {!isMobile &&
            (props.collapse ? (
              <img src={slp} alt="Logo" style={{ height: 30 }} />
            ) : (
              <img src={logo} alt="Logo" style={{ width: 200 }} />
            ))}
        </Col>
      );
   }
  useEffect(() => {
    // getProfile();
    // initiateSocket(user);
    // subscribeToMessage((err, data) => {
    //   if(err) {
    //     console.log('socket error', err);
    //   }
    //   console.log('socket data is', data);
    // });
    // return () => {
    //   disconnectSocket();
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const overlayMenu = (
    <Menu
      // mode="horizontal"
      theme="dark"
      // style={{ backgroundColor: "transparent", border: "none" }}
      className="headerMenuDropdown"
      triggerSubMenuAction="click"
      // title='dfs'
    >
      <Menu.Item
        key="billing:1"
        onClick={() => {
          history.push("/user/billing");
        }}
      >
        Billing
      </Menu.Item>
      <Menu.Item
        key="setting:2"
        onClick={() => {
          history.push("/user/settings");
        }}
      >
        Settings
      </Menu.Item>
      <Menu.Item key="logout:2" onClick={handleLogOut}>
        Logout
      </Menu.Item>
    </Menu>
  );
    return (
      <StyledHeaderAuth>
        <Row style={{ position: "static" }}>
          <Link to="/">
            <HeaderLeft isMobile={props.isMobile} />
          </Link>
        </Row>

        <div
          style={{
            position: "fixed",
            right: 10,
            top: 0,
            display: "flex",
            // justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TopNavLink className="test">
              <a href="/" onClick={handleNeedHelpPress}>
                Need help?
              </a>
            </TopNavLink>
            {/* <TopNavLink className="test">
            <a href="#">Profile</a>
          </TopNavLink> */}
            <TopNavLink>
              <Dropdown overlay={overlayMenu} className='headerMenuSubItems' trigger={["click", "hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Profile <CaretDownFilled />
                </a>
              </Dropdown>
            </TopNavLink>
          </div>
        </div>
        {helpModalVisible && (
          <NeedHelpModal
            isOpen={helpModalVisible}
            onClose={handleHelpModalClose}
          />
        )}
      </StyledHeaderAuth>
    );
};

export default HeaderAuth;
