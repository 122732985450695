import React from "react";
import { ModalProps, Modal } from "antd";

interface Props extends ModalProps {
  children: React.ReactElement;
}

const AntdModal = (props: Props) => {
  return (
    <Modal
      bodyStyle={{
        maxHeight: "calc(100vh - 300px)",
        overflowY: "auto",
        padding: "10px 24px",
        ...props.bodyStyle,
      }}
      {...props as any}
    >
      {props.children as any}
    </Modal>
  );
};

export default AntdModal;
