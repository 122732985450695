import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  size?: number | string;
}

const LoadingSpinner = ({ size }: Props) => {
  let antIcon = <LoadingOutlined style={{ fontSize: size || 25 }} spin />;
  return <Spin indicator={antIcon} />;
};

export default LoadingSpinner;

export const LoadingSpinnerView = ({ size }: Props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '85vh' }}>
      <LoadingSpinner size={size || 80} />
    </div>
  );
};
