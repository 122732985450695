import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../helper';
import UserProvider from 'helper/UserContext';

const PrivateRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !!getToken() ? (
          <UserProvider>
            <Component />
          </UserProvider>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoutes;
