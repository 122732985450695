import React from 'react';
import ChangePasswordForm from 'components/Login/ChangePasswordForm';
import { DashboardContainer } from 'components/Container/Container';
interface Props {}

const ChangePasswordView = (props: Props) => {
  
  return <DashboardContainer>{<ChangePasswordForm />}</DashboardContainer>;
};

export default ChangePasswordView;
