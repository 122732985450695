import React, { useState, useEffect } from "react";
import Container, { DashboardContainer } from "components/Container/Container";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import { Button, message, notification } from "antd";

import AddCollectionModal from "components/Collection/AddCollectionModal";
import CollectionTable from "components/Collection/CollectionTable";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import CollectionItemsTable from "components/Collection/CollectionItemsTable";
import CollectionColumnMappingTable from "components/Collection/CollectionColumnMappingTable";
// import AddCollectionModal from "components/AddCollectionModal/AddCollectionModal";
import { Link, useLocation, useParams } from "react-router-dom";
import Api from "api";
import { useHistory } from "react-router-dom";

interface Props {}

const PAGE_LIMIT = 10;

const CollectionColumnsMapping = (props: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [collectionData, setCollectionData] = useState<any>(null);
  const location = useLocation();
  const { collectionId } = useParams();
  const fetchCollection = async () => {
    setLoading(true);
    try {
      const { data } = await Api.get(`/collections/${collectionId}`, {});

      if (data.data && data.data.is_user_upload && !data.data.mapped) {
        setCollectionData(data.data);
        setLoading(false);
      } else if ( data.error_count && !data.error_fixed){
        notification.error({
            message: "Invalid action",
            description: "This collection has already been mapped",
          });
          history.push(`/collections/${collectionId}`);
      } else {
        notification.error({
          message: 'Invalid action',
          description: "Collection cannot be mapped at the moment"
        })
        setLoading(false);
      }
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch Colletion";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
    // setLoading(false);
  };
  useEffect(() => {
    fetchCollection();
  // const collection = location.state && location.state.collection;
  // if(collection) {
  //    setCollectionData(collection);
  //    setLoading(false);
  // } else {
  //   fetchCollection();
  // }

  }, []);

  if (loading) return <LoadingSpinnerView size={80} />;
  if(!collectionData) {
    return (
      <DashboardContainer>
        <div>Error! No data found.</div>
      </DashboardContainer>
    );
  }
  return (
    <DashboardContainer>
      <CollectionColumnMappingTable
        collectionData={collectionData}
        // type="animal"
        // tableLoading={tableLoading}
        // pageLimit={PAGE_LIMIT}
      />
    </DashboardContainer>
  );
};

export default CollectionColumnsMapping;
