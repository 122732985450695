import React, { useState } from "react";
import { Modal, Button, Input, message, Alert } from "antd";
import Api from "api";
import styled from 'styled-components';
import config from "config";
const MIN_TICKET_CHARACTER_LIMIT = +config.MIN_TICKET_CHARACTER_LIMIT

const InfoContainer = styled.p`
  text-align: left;
  color: red;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 200;
`

const NeedHelpModal = ({ isOpen, onClose }) => {
  const [body, setBody] = useState<string>('');
  const [hasMinLength, setHasMinLength] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const handleTicket = async () => {
    
    setLoading(true);
    try {
      const { data } = await Api.post("/tickets", {
        body,
      });
      setLoading(false);
      if (data && data.messages) {
        message.success({ content: data.messages[0], duration: 3 });
        onClose('ok');
      }
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Create Ticket";
      setErr(errorMsg);
      setTimeout(() => {
        setErr('');
      }, 3000);
    }
  };
  return (
    <Modal
      title="Create a ticket"
      style={{ textAlign: "center", fontWeight: "bold" }}
      visible={isOpen}
      onCancel={() => onClose("cancel")}
      footer={[
        <Button key="back" onClick={() => onClose("cancel")}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          disabled={!hasMinLength}
          onClick={handleTicket}
        >
          Submit
        </Button>,
      ]}
    >
      {err && err.length && <Alert message={err} type="error" style={{marginBottom: 10}}/>}
      <Input.TextArea
        rows={4}
        placeholder="Please let us know your thoughts"
        onChange={(e) => {
          setBody(e.target.value);
          if(e.target.value.length >= MIN_TICKET_CHARACTER_LIMIT){
            setHasMinLength(true)
          }
          if(e.target.value.length < MIN_TICKET_CHARACTER_LIMIT && hasMinLength){
            setHasMinLength(false)
          }
        }}
      />
      {!hasMinLength && (
         <InfoContainer>Minimum character limit is {MIN_TICKET_CHARACTER_LIMIT}</InfoContainer>
      )}
    </Modal>
  );
};
export default NeedHelpModal;

