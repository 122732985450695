import React, { useEffect, useState } from 'react';
import {  Layout as AntdLayout, message } from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import Api from 'api';
import { removeToken, removeUserData } from 'helper';
import VerifyIdentityForm from 'components/Login/VerifyIdentityForm';
import { LoadingSpinnerView } from 'components/LoadingSpinner/LoadingSpinner';

interface Props {}

const VerifyIdentityView = (props: Props) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>();
  const [verifyToken, setVerifyToken] = useState<string>();
  const [questions, setQuestions] = useState<any>({});
  removeUserData();
  removeToken();
  const fetchSecurityQuestions = async (token) => {
    setLoading(true);
    try {
      const { data } = await Api.get('/auth/user_security_questions', {
        token: token
      });
      setLoading(false);
      if(data && data.questions) {
        setQuestions(data.questions);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to verify";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  useEffect(() => {
    const queries = qs.parse(location.search);
    if (!queries || !queries.token) {
      message.error({ content: "Invalid verification url", duration: 5 });
    } else {
      setVerifyToken(queries.token as string);
      fetchSecurityQuestions(queries.token);
    }
  }, [location.search]);
  if (loading) return <LoadingSpinnerView size={80} />;
  
  return (
    <AntdLayout>
      <HeaderNoAuth />
      {questions &&
        questions.security_question1 &&
        questions.security_question2 && (
          <VerifyIdentityForm
            verifyToken={verifyToken}
            security_question1={questions.security_question1}
            security_question2={questions.security_question2}
          />
        )}
    </AntdLayout>
  );
};

export default VerifyIdentityView;
