import { DashboardContainer } from 'components/Container/Container'
import React from 'react'

interface Props {
    
}

const Error404 = (props: Props) => {
    return <DashboardContainer>404</DashboardContainer>;
}

export default Error404
