import { createGlobalStyle } from 'styled-components';
import { ThemeConfig } from 'theme';

const GlobalStyles: any = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  .ant-select-item-option-content {
    max-width: 500px;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
  }
  & .content-scrollbar .scrollbar-track{
    width: 3px;
  }
  & .content-scrollbar .scrollbar-thumb{
    width: 3px;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
}
// input::placeholder {
//   color: #000 !important; 
// }
// .ant-menu-submenu-popup {
//     width: 60px;
//   }
.headerMenuDropdown {
  // width: 80px;
  background-color: ${ThemeConfig.colors.darkBlue}
}
.headerMenuDropdown .ant-dropdown-menu-item {
    margin-bottom: 0px;
    height: 30px;
    width: 80px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    padding: 3px 15px 3px 10px;
    color: #fff;
    font-weight: 700;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
  }


  .react-resizable {
  position: relative;
  background-clip: padding-box;
}

 .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
@keyframes blinker {
  from {
    opacity: 0.9;
  }
to {
    opacity: 0.1;
  }
}
`;

export default GlobalStyles;
