import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import PrivateRoutes from './PrivateRoutes';
import Login from '../views/auth/Login';
import SignUp from '../views/auth/SignUp';
import Confirm from '../views/auth/Confirm';
import ReConfirm from '../views/auth/ReConfirm';
import Error404 from './Error404';
import { getToken } from '../helper';
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import TooManyIPs from "views/auth/TooManyIPs";
import VerifyIdentity from 'views/auth/VerifyIdentity';
import SignupCheckoutHandler from "views/auth/SignupCheckoutHandler";
import ResetSecurityQuestions from 'views/auth/ResetSecurityQuestions';

interface Props {}

const RouterPath: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Signup For backward compativility */}
        <Route
          exact
          path="/users/sign_up"
          render={({ location }) => (
            <Redirect to={{ ...location, pathname: "/signup" }} />
          )}
        />
        {/* {getToken() ? <Redirect to="/" /> : <Redirect to="/signup" />} */}
        {/* </Route> */}
        <Route path="/login">
          {getToken() ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/signup">
          {getToken() ? <Redirect to="/" /> : <SignUp />}
        </Route>
        <Route path="/confirm">
          <Confirm />
        </Route>
        <Route path="/verifyIdentity">
          <VerifyIdentity />
        </Route>
        <Route path="/resend_confirmation">
          <ReConfirm />
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/reset_password">
          <ResetPassword />
        </Route>
        <Route path="/error_too_many_ip">
          <TooManyIPs />
        </Route>
        <Route path="/reset_security_questions">
          <ResetSecurityQuestions />
        </Route>
        {!getToken() && (
          <Route
            path="/checkout/cancel"
            render={(props) => (
              <SignupCheckoutHandler
                {...props}
                checkoutInfo={{ type: "cancel" }}
              />
            )}
          />
        )}
        {!getToken() && (
          <Route
            path="/checkout/success"
            render={(props) => (
              <SignupCheckoutHandler
                {...props}
                checkoutInfo={{ type: "success" }}
              />
            )}
          />
        )}
        <PrivateRoutes path="/" component={ProtectedRoutes} />

        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterPath;
