export function getTableScroll({ extraHeight, id }) {
  if (typeof extraHeight == "undefined" || extraHeight == null) {
    //   Default bottom pagination 64 +  Margin 20 + Padding 10 + Plus Extra 10
    extraHeight = 112;
  }
  let tHeader: any = null;
  if (id) {
    tHeader = document.getElementById(id)
      ? document
          .getElementById(id)
          ?.getElementsByClassName("ant-table-thead")[0]
      : null;
  } else {
    tHeader = document.getElementsByClassName("ant-table-thead")[0];
  }
  let tHeaderBottom = 0;
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom;
  }
  let vh;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    vh = `${window.innerHeight}px`;
  } else {
    vh = "100vh";
  }
  let height = `calc(${vh} - ${tHeaderBottom + extraHeight}px)`;
  console.log("Table Height", height);
  return height;
}
