import React, { useEffect, useState } from 'react';
import {  Button, Layout as AntdLayout, message, notification, Result } from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import styled from "styled-components";
import * as qs from "query-string";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Api from 'api';
import { LoadingSpinnerView } from 'components/LoadingSpinner/LoadingSpinner';

interface Props {
  checkoutInfo?: any;
}
const CheckoutWrapper = styled.div`
  overflow: scroll;
  width: 100vw;
  margin-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
  }
  .ant-form-item-control {
    padding: 5px;
  }
  .ant-form-item {
    // margin-bottom: 15px;
  }
  .ant-form-item-label {
    // padding: 0px;
  }
`;
const confirmationMessage =
  "One time payment is successful, you will get one time access to download collection.";
const OneTimePaymentCheckoutHandler = (props: Props) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [collectionId, setCollectionId] = useState<any>(null);
  const [result, setResult] = useState<string>("Failed to handle payment");
  const { checkoutInfo = 'success' } = props;
  const type =
    checkoutInfo && checkoutInfo.type === "cancel" ? "error" : "success";
  const history = useHistory();
  useEffect(() => {
    if (type === 'error') {
      setLoading(false);
      setResult("One time payment is canceled");      
    } else {
      const queries = qs.parse(location.search);
      if (!queries || !queries.collection_id || !queries.session_id) {
        message.error({ content: "Invalid request", duration: 5 });
        setLoading(false);
      } else {
        setLoading(false);
        notification.success({
          message: 'Payment successful',
          description: 'You will be able to download the collection'
        })
        history.push(`/collections/${queries.collection_id}`);
        return;
        // setResult("One time payment is successful");
        // handleCancelCheckout(queries.session_id);
      }
      setLoading(false);
      // setResult(confirmationMessage);
    }
  }, [location.search]);
  if (loading) return <LoadingSpinnerView size={80} />;
  
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <CheckoutWrapper>
        <Result
          status={type}
          title="One time Payment"
          subTitle={result || ""}
          extra={[
            <Button type="primary" key="console">
              <a href="/collections">Collections</a>
            </Button>,
          ]}
        />
      </CheckoutWrapper>
    </AntdLayout>
  );
};

export default OneTimePaymentCheckoutHandler;
