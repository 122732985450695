import React from 'react';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import GlobalStyles from './globalStyles';
// import 'antd/dist/antd.css';
import Theme from './theme';
import config from 'config';
const stripePromise = loadStripe(config.stripe_pub_key as string);

const App: React.FC = () => {
  const options = {
    // clientSecret: "{{CLIENT_SECRET}}",
  };
  return (
    <React.Fragment>
      <Elements stripe={stripePromise} options={options}>
        <BrowserRouter>
          <Theme>
            <GlobalStyles />
            <Router />
          </Theme>
        </BrowserRouter>
      </Elements>
    </React.Fragment>
  );
};

export default App;
