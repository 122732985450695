import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Slider,
  Radio,
  Upload,
  Row,
  Spin,
} from "antd";
import AntdModal from "components/AntdModal/AntdModal";
import { ThemeConfig } from 'theme';
import { useHistory } from "react-router-dom";
import Api from "api";
import { InboxOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { useUser } from "helper/UserContext";
const { Dragger } = Upload;

const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: () => void;
}

const UploadCollectionFileModal = ({ visible, onClose }: Props) => {
  const [form] = Form.useForm();
  const { user } = useUser();
  const canUpload = user && user.status !== 'free';
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [upFile, setUpFile] = useState<any>(null);
  useEffect(() => {
   form && form.resetFields();
  }, [form]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      setUpFile(e[0]);
      return e;
    }
    setUpFile(e.file);
    return e && e.fileList;
  };
  const onUploadSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", upFile);
      formData.append("name", values.name);
      formData.append("type", values.type);
      const { data } = await Api.post("/collections/upload_excel_file", formData);
      setLoading(false);
      if (data && data.data && data.data.attributes && data.data.id) {
        notification.success({
          message: 'Collection Created',
          description: `Collection '${data.data.attributes.name}' created`
        });
        history.push({
          pathname: `/collections/${data.data.id}/save_mapped_columns`,
          state: {
            collection: data.data
          }
        });
        onClose();
      } else {
        notification.error({
          message: 'Create collection failed',
          description: "Invalid response from server"
        })
      }
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Create Ticket";
          notification.error({
            message: "Create collection failed",
            description: errorMsg,
          });
    }
  };

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = () => {
    
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
         await onUploadSubmit(values);
         setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadFile = async () => {
    try {
      const res = await Api.get(
        `/collections/download_sample_file`,
        {},
        { responseType: "blob" }
      );
      if (res && res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "SLP_sample.xlsx"
        );
        document.body.appendChild(link);
        link.click();
      } else {
        notification.error({
          message: "Download failed",
          description: "Failed to download excel",
        });
      }
    } catch (e) {
      notification.error({
        message: "Download failed",
        description: "Failed to download excel file",
      });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      style={canUpload ? {} : {textAlign: 'center'}}
      title="Import"
      footer={
        canUpload ? (
          [
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={onSubmit}
            >
              Upload
            </Button>,
            <Button key="cancel" onClick={closeModal}>
              Close
            </Button>,
          ]
        ) : (
          <Row style={{ justifyContent: "center" }}>
            <Button key="back" type="text" onClick={closeModal}>
              No Thanks
            </Button>
          </Row>
        )
      }
      forceRender
    >
      {canUpload ? (
        <Spin spinning={loading}>
          <Form form={form} layout="vertical">
            <Form.Item
              rules={[
                { required: true, message: "Please select collection type!" },
              ]}
              label="Choose Collection"
              name="type"
            >
              <Select placeholder="">
                <Option value="plant">Plant</Option>
                <Option value="animal">Wildlife</Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your collection name!",
                },
              ]}
              label={
                <>
                  <span>Collection name &nbsp;</span>
                </>
              }
              name="name"
            >
              <Input placeholder="Enter collection name" />
            </Form.Item>
            <Form.Item label="Dragger">
              <Form.Item
                name="file"
                rules={[
                  { required: true, message: "Please upload a valid file" },
                ]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  // action="/upload.do"
                  beforeUpload={() => {
                    return false;
                  }}
                  maxCount={1}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a ddsingle or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Button type="link" onClick={downloadFile} style={{ padding: 0 }}>
              (Download Sample File)
            </Button>
          </Form>
        </Spin>
      ) : (
        <Row style={{ justifyContent: "center" }}>
          {/* <h3 style={{ textAlign: "center" }}>
            The Import a list feature is only available to Professional users
          </h3> */}
          <p className="packageDescription" style={{fontSize: 20,fontWeight: 400, textAlign: 'center'}}>
            The Import a list feature is only available to Professional users
          </p>
          <Button type="primary" href="/user/billing">
            Upgrade
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default UploadCollectionFileModal;
