import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Col,
  Result,
  message,
  Row
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import Api from "api";
import { ThemeConfig } from "theme";
import { typeMapping } from "helper/securityAnswerValidator";

const LoginWrapper = styled.div`
  width: 100vw;
  margin-top: 70px;
  min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;


interface Props {
  verifyToken: any;
  security_question1: string;
  security_question2: string;
}

const VerifyIdentityForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>();
  const [result, setResult] = useState<string>();
  const handleVerifyWhitelistIP = async (values) => {
    setLoading(true);
    try {
      const { data } = await Api.post("/auth/verify_security_answers", {
        ...values,
        identity_token: props.verifyToken,
      });
      setLoading(false);
      if (data && data.messages) {
        setResult(data.messages[0]);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to verify";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  if (result && result.length) {
    return (
      <LoginWrapper>
        <Result
          status="success"
          title="Verified"
          subTitle={result}
          extra={[
            <Button type="primary" key="console">
              <a href="/login">Login</a>
            </Button>,
          ]}
        />
      </LoginWrapper>
    );
  }
  return (
    <LoginWrapper>
      <FormWrapper>
        <Col lg={{ span: 8, offset: 8 }}>
          <h4 className="mb-3" style={{ color: "#494848" }}>
            Whitelist your IP
          </h4>
          <Form
            {...layout}
            form={form}
            onFinish={handleVerifyWhitelistIP}
            name="resetPassword"
            layout="vertical"
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={props.security_question1}
                  name="answer1"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Enter answer 1",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const questionValue = props.security_question1;

                        if (!questionValue || !value) {
                          return Promise.resolve();
                        }
                        const validationRegex =
                          typeMapping[questionValue].regex || false;
                        const validationTruth =
                          typeMapping[questionValue].truth;
                        if (!validationRegex) {
                          return Promise.resolve();
                        }
                        if (
                          validationTruth
                            ? validationRegex.test(value)
                            : !validationRegex.test(value)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(typeMapping[questionValue].message)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Answer 1"
                    // onPressEnter={login}
                    // onChange={(e) =>
                    //   setPassword(e.target.value)
                    // }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={props.security_question2}
                  name="answer2"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Enter answer 2",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const questionValue = props.security_question2;

                        if (!questionValue || !value) {
                          return Promise.resolve();
                        }
                        const validationRegex =
                          typeMapping[questionValue].regex || false;
                        const validationTruth =
                          typeMapping[questionValue].truth;
                        if (!validationRegex) {
                          return Promise.resolve();
                        }
                        if (
                          validationTruth
                            ? validationRegex.test(value)
                            : !validationRegex.test(value)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(typeMapping[questionValue].message)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Answer 2"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit"
                // onClick={login}
                loading={loading}
              >
                Add IP
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: ThemeConfig.colors.grey,
              }}
            >
              <a className="greyLink" href="/login">
                Login
              </a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: ThemeConfig.colors.grey,
              }}
            >
              <a className="greyLink" href={`/reset_security_questions?token=${props.verifyToken}`}>
                Reset security questions
              </a>
            </div>
          </Form>
        </Col>
      </FormWrapper>
    </LoginWrapper>
  );
};

export default VerifyIdentityForm;
