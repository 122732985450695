const SMALL_WIDTH = 150;
const MEDIUM_WIDTH = 200;
const MAX_WIDTH = 300;

export const PLANT_COMMON_COLUMNS_HEADERS = [
  "plant_type",
  "genus",
  "species",
  "variety",
  "family_name",
];

export const ENTERPRISE_COLUMNS_HEADERS = [
  "usace_wetland_status",
  "bioregional_distributions",
  "counties",
];

export const PLANT_COMMON_COLUMNS = [
  {
    title: "Plant Type",
    dataIndex: "plant_type",
    key: "plant_type",
    className: "centered",
    sorter: true,
    show: false,
  },
  {
    title: "Name",
    sorter: true,
    show: true,
    children: [
      {
        title: "Family",
        headerClassName: "family-header",
        dataIndex: "family_name",
        key: "family_name",
        className: "centered capitalize",
        sorter: true,
        width: 50,
        show: true,
      },
      {
        title: "Genus",
        headerClassName: "genus-header",
        dataIndex: "genus",
        key: "genus",
        className: "centered capitalize",
        width: 50,
        sorter: true,
        show: true,
      },
      {
        title: "species",
        dataIndex: "species",
        key: "species",
        className: "centered",
        width: 50,
        sorter: true,
        show: true,
      },
      {
        title: "Variety/Subspecies",
        dataIndex: "variety",
        key: "variety",
        className: "centered",
        width: MEDIUM_WIDTH,
        sorter: true,
        show: true,
      },
    ],
  },
];

export const EJEPSON_COLUMNS = [
  {
    title: "Imported name from user",
    dataIndex: "database_original_name",
    key: "database_original_name",
    className: "centered",
    sorter: true,
    show: true,
  },
  {
    title: "Common Names",
    dataIndex: "common_names",
    key: "common_names",
    sorter: true,
    show: true,
  },
  {
    title: "Synonyms",
    dataIndex: "synonyms",
    key: "synonyms",
    className: "centered",
    sorter: true,
    show: true,
  },
  {
    title: "California Rare Plant Rank",
    dataIndex: "california_rare_plant_rank",
    key: "california_rare_plant_rank",
    sorter: true,
    show: true,
  },
  {
    title: "State Listing Status",
    dataIndex: "state_listing_status",
    key: "state_listing_status",
    className: "centered",
    sorter: true,
    show: true,
  },
  {
    title: "Federal Listing Status",
    dataIndex: "federal_listing_status",
    key: "federal_listing_status",
    className: "centered",
    sorter: true,
    show: true,
  },
  {
    title: "Bioregional Distributions",
    dataIndex: "bioregional_distributions",
    key: "bioregional_distributions",
    sorter: true,
    show: true,
  },
  {
    title: "Distribution Outside Of California",
    dataIndex: "distribution_outside_of_california",
    key: "distribution_outside_of_california",
    sorter: true,
    show: false,
  },
  {
    title: "Native",
    dataIndex: "native",
    key: "native",
    sorter: true,
    show: true,
    className: "centered",
  },
  {
    title: "IPC Listed Weed",
    dataIndex: "ipc_listed_weed",
    key: "ipc_listed_weed",
    sorter: true,
    show: false,
    className: "centered",
    render: (record) => (record ? "Yes" : "No"),
  },
  {
    title: "USACE Wetland Status",
    dataIndex: "usace_wetland_status",
    key: "usace_wetland_status",
    sorter: true,
    show: false,
    render: (record) => (record ? record.toUpperCase() : ""),
  },
  {
    title: "USACE Wetland Name",
    dataIndex: "usace_wetland_name",
    key: "usace_wetland_name",
    sorter: true,
    show: false,
    render: (record) =>
      record ? record.charAt(0).toUpperCase() + record.slice(1) : "",
  },
  {
    title: "Forest Service Rank",
    dataIndex: "forest_service_rank",
    key: "forest_service_rank",
    sorter: true,
    show: false,
  },
  {
    title: "BLM Rank",
    dataIndex: "blm_rank",
    key: "blm_rank",
    sorter: true,
    show: false,
  },
  {
    title: "Habitat",
    dataIndex: "habitat",
    key: "habitat",
    sorter: true,
    show: false,
  },
  {
    title: "Elevation Range",
    dataIndex: "elevation_range",
    key: "elevation_range",
    sorter: true,
    show: false,
  },
  {
    title: "Flowering Time",
    dataIndex: "flowering_time",
    key: "flowering_time",
    className: "centered",
    sorter: true,
    show: false,
  },
  {
    title: "Local/County Rank",
    dataIndex: "local_county_rank",
    key: "local_county_rank",
    sorter: true,
    show: false,
  },
  {
    title: "Link to eFlora Jepson",
    dataIndex: "link_to_eflora_jepson",
    key: "link_to_eflora_jepson",
    sorter: true,
    show: false,
  },
  {
    title: "Cal Photo Link",
    dataIndex: "cal_photo_link",
    key: "cal_photo_link",
    sorter: true,
    show: false,
  },
  {
    title: "Global Rank",
    dataIndex: "global_rank",
    key: "global_rank",
    sorter: true,
    show: false,
  },
  {
    title: "State Rank",
    dataIndex: "state_rank",
    key: "state_rank",
    sorter: true,
    show: false,
  },
  {
    title: "Jepson Eflora And Other Notes",
    dataIndex: "jepson_eflora_other_notes",
    key: "jepson_eflora_other_notes",
    sorter: true,
    show: false,
  },
  {
    title: "Counties",
    dataIndex: "counties",
    key: "counties",
    sorter: true,
    show: false,
    render: (record) =>
      record && record.length > 0
        ? record
            .join("\n")
            .split("\n")
            .map((item, index) => {
              return index < record.join("\n").split("\n").length - 1 ? (
                <span>{item.trim()}, </span>
              ) : (
                <span>{item.trim()}</span>
              );
            })
        : null,
  },
  {
    title: "CNPS Quads",
    dataIndex: "cnps_quads",
    key: "cnps_quads",
    sorter: true,
    show: false,
  },
  {
    title: "CNPS California Counties and Islands",
    dataIndex: "cnps_california_counties_and_islands",
    key: "cnps_california_counties_and_islands",
    sorter: true,
    show: false,
  },
  // {
  //   title: "Note",
  //   dataIndex: "note",
  //   key: "note",
  //   className: "centered",
  //   // width: MAX_WIDTH,
  //   sorter: true,
  //   show: false,
  //   render: (record) => {
  //     return (
  //       <div>
  //         <textarea
  //           rows={5}
  //           cols={40}
  //           className="item-note"
  //           value={record.original.note}
  //           data-item-id={record.original.item_id}
  //           // onChange={onNoteChange}
  //         ></textarea>
  //         <button
  //           className="update-note"
  //           data-item-id={record.original.item_id}
  //           // onClick={onUpdateNote}
  //         >
  //           Update
  //         </button>
  //       </div>
  //     );
  //   },
  // },
];
export const NameInnerColumnKeys = ["family", "genus", "species", "variety"];
export const NameColumn = {
  title: "Name",
  sorter: true,
  show: true,
  children: [
    {
      title: "Family",
      headerClassName: "family-header",
      dataIndex: "family",
      key: "family",
      className: "centered capitalize",
      sorter: true,
      width: 100,
      ellipsis: true,
      show: true,
    },
    {
      title: "Genus",
      headerClassName: "genus-header",
      dataIndex: "genus",
      key: "genus",
      className: "centered capitalize",
      width: 100,
      sorter: true,
      ellipsis: true,
      show: true,
    },
    {
      title: "species",
      dataIndex: "species",
      key: "species",
      className: "centered",
      width: 100,
      sorter: true,
      ellipsis: true,
      show: true,
    },
    {
      title: "Variety/Subspecies",
      dataIndex: "variety",
      key: "variety",
      className: "centered",
      width: MEDIUM_WIDTH,
      sorter: true,
      ellipsis: true,
      show: true,
    },
  ],
};
