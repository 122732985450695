import React, { useState } from "react";
import { LayoutProps } from "./interface";
import { Layout as AntdLayout } from "antd";
import Sidebar from "./Sidebar";
import { StyledLayout, StyledContent } from "./LayoutStyles";
import Scrollbar from "react-smooth-scrollbar";
import HeaderAuth from "./Header/HeaderAuth";
import Media from "react-media";
import FloatingSideMenu from "./Header/FloatingSideMenu";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [collapse, setCollapse] = useState(true);
  return (
    <Media
      queries={{
        small: "(max-width: 960px)",
      }}
    >
      {(matches) => {
        const isMobile = matches.small;
       return (
         <StyledLayout>
           <HeaderAuth
             toggleSidebar={setCollapse}
             collapse={collapse}
             isMobile={isMobile}
           />
           {isMobile && <FloatingSideMenu toggleSidebar={setCollapse} collapse={collapse} isMobile={isMobile}/> }
           <Sidebar
             toggleSidebar={setCollapse}
             collapse={collapse}
             isMobile={isMobile}
           />
           <AntdLayout
             style={{
               width: isMobile ? ('100%'): (collapse ? "calc(100% - 76px)" : "calc(100% - 250px)"),
             }}
           >
             {/* <Header toggleSidebar={setCollapse} collapse={collapse} /> */}
             <Scrollbar
               className="content-scrollbar"
               style={{
                 height: "100%",
                 width: "100%",
               }}
             >
               <StyledContent>{children}</StyledContent>
             </Scrollbar>
           </AntdLayout>
         </StyledLayout>
       );
      }}
    </Media>
  );
};

export default Layout;
