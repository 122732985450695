import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, Slider, Radio } from "antd";
import AntdModal from "components/AntdModal/AntdModal";
import { ThemeConfig } from 'theme';
import { useHistory } from "react-router-dom";
import Api from "api";

const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: () => void;
  type?: any;
}
const collectionTypes = ["animal", "plant"];
const AddCollectionModal = ({ visible, onClose, type = null }: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>();
  useEffect(() => {
   form && form.resetFields();
  }, [form]);

  const onCreateCollection = async (values) => {
    setLoading(true);
    try {
      const { data } = await Api.post("/collections", values);
      setLoading(false);
      if (data && data.data && data.data.attributes && data.data.id) {
        // message.success({ content: data.messages[0], duration: 3 });
        // onClose("ok");
        notification.success({
          message: 'Collection Created',
          description: `Collection '${data.data.attributes.name}' created`
        });
        // history.push(`/collections/${data.data.id}`);
        window.location.href = `/collections/${data.data.id}`;
      } else {
        notification.error({
          message: 'Create collection failed',
          description: "Invalid response from server"
        })
      }
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Create Ticket";
          notification.error({
            message: "Create collection failed",
            description: errorMsg,
          });
      // setErr(errorMsg);
      // setTimeout(() => {
      //   setErr("");
      // }, 3000);
    }
  };

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
         onCreateCollection(values);
      })
      .catch(() => {});
  };

  return (
    <AntdModal
      visible={visible}
      onCancel={closeModal}
      style={{}}
      title="Add New Collection"
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
          Add
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
      ]}
      forceRender
    >
      <Form form={form} layout="vertical" initialValues={type ? {type} : {}}>
        <Form.Item
          rules={[
            { required: true, message: "Please select collection type!" },
          ]}
          label="Collection Type"
          name="type"
        >
          {/* <Input placeholder="Enter collection url" /> */}
          <Radio.Group
            options={collectionTypes}
            defaultValue={type}
            size="large"
            optionType="button"
            style={{ width: "100%", textTransform: 'capitalize' }}
            onChange={() => {
              console.log(form.getFieldsValue());
            }}
            // value={value1}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Please input your collection name!" },
          ]}
          label={
            <>
              <span>Collection name &nbsp;</span>
              <span style={{ color: ThemeConfig.colors.grey }}>
                {" "}
                (Dont worry you can change this anytime)
              </span>
            </>
          }
          name="name"
        >
          <Input placeholder="Enter collection name" />
        </Form.Item>
      </Form>
    </AntdModal>
  );
};

export default AddCollectionModal;
