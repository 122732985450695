/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Error404 from '../Error404';
import Home from 'views/home/Home';
import Collections from 'views/collections/Collections';
import Settings from 'views/auth/Settings';
import ChangePassword from 'views/auth/ChangePassword';
import Billing from 'views/billing/Billing';
import Layout from '../../components/Layout';
import { useUser } from 'helper/UserContext';
import { message } from 'antd';
import Api from 'api';
import SingleCollection from 'views/collections/SingleCollection';
import CollectionColumnsMapping from 'views/collections/CollectionColumnsMapping';
import { LoadingSpinnerView } from 'components/LoadingSpinner/LoadingSpinner';
import OneTimePaymentCheckoutHandler from 'views/auth/OneTimePaymentCheckoutHandler';
import UploadCollectionView from "views/collections/UploadCollectionView";

interface Props {}

const AdminRouterDefs = () => {
  return (
    <Switch>
      <Route path="/user/settings" component={Settings} />
      <Route path="/user/change_password" component={ChangePassword} />
      <Route path="/user/billing" component={Billing} />
      <Route
        path="/settings/billing"
        render={(props) => <Redirect to="/user/billing" />}
      />
      <Route
        path="/checkout/success"
        render={(props) => (
          <Billing {...props} checkoutInfo={{ type: "success" }} />
        )}
      />
      <Route
        path="/checkout/cancel"
        render={(props) => (
          <Billing {...props} checkoutInfo={{ type: "cancel" }} />
        )}
      />
      <Route
        path="/checkout/payment_cancel"
        render={(props) => (
          <OneTimePaymentCheckoutHandler
            {...props}
            checkoutInfo={{ type: "cancel" }}
          />
        )}
      />
      <Route
        path="/checkout/payment_success"
        render={(props) => (
          <OneTimePaymentCheckoutHandler
            {...props}
            checkoutInfo={{ type: "success" }}
          />
        )}
      />
      <Route exact path="/collections" component={Collections} />
      <Route
        exact
        path="/collections/:collectionId/save_mapped_columns"
        component={CollectionColumnsMapping}
      />
      <Route
        exact
        path="/collections/upload_excel_file"
        component={UploadCollectionView}
      />
      <Route
        exact
        path="/collections/:collectionId"
        component={SingleCollection}
      />
      <Route
        exact
        path="/"
        render={(props) => <Redirect to="/collections" />}
      />
      <Route component={Error404} />
    </Switch>
  );
};

const ProtectedRoutes = (props: Props) => {
  const { user, setUser} = useUser();
  const handleProfileData = (pData) => {
    setUser(pData);
  };
  const fetchUserProfile = async () => {
    try {
      const { data } = await Api.get("/account/profile", {});
      handleProfileData({
        id: data.data.id,
        ...data.data.attributes,
      });
    } catch (e: any) {
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Fetch Profile";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  }

  useEffect(() => {
    fetchUserProfile();
  }, []);

  if (!user) return <LoadingSpinnerView size={80} />;
  // if (error) return `Problem`;

  return <Layout>{AdminRouterDefs()}</Layout>;
};

export default ProtectedRoutes;
