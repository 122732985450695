import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';



export const StyledSidebar = styled(AntdLayout.Sider)`
  position: relative;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 998;
  margin-top: 70px;
  background: #2bb0e7;
  background: #2bb0e7;
  // background: red;
  // & .ant-layout-sider {
  //   width: 20px;
  // }
  ${(props) =>
    props.collapsed
      ? `max-width: 76px !important;
    min-width: 76px !important;
    width: 76px !important;`
      : ``}
  ${(props: any) =>
    props.$isMobile
      ? props.collapsed
        ? `max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;`
        : ``
      : props.collapsed
      ? `max-width: 76px !important;
    min-width: 76px !important;
    width: 76px !important;`
      : ``}

  & aside {
    display: none;
  }
  & .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 76px;
  }
  & .ant-menu-dark {
    background: transparent;
  }
  & .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
    box-shadow: none;
  }
  & .ant-menu.ant-menu-dark .ant-menu-item,
  .ant-menu-submenu {
    /* margin: 0; */
    padding-left: 30px !important;
    padding-right: 0;
    margin-bottom: 0px;
    margin-top: 1px;
  }
  & .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: #002140;
  }
  & .ant-menu-submenu-title {
    padding-left: 0px !important;
  }
  .ant-menu-item span,
  .ant-menu-submenu {
    color: #fff;
    font-weight: bold;
  }
` as any;

export const StyledSidebarHeader = styled(AntdLayout.Header)`
  position: relative;
  background: transparent;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
  & h1 {
    font-size: 2rem;
    line-height: 1.8;
    color: #f7f7f7;
    margin: 0;
  }

  
`;
