import React, { useState } from "react";
import { Modal, Button, Input, message, Alert, Form, Col, Row, notification, Select } from "antd";
import Api from "api";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { ThemeConfig } from "theme";
const InviteWrapper = styled.div`
  // margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const MergeCollectionModal = ({
  isOpen,
  onClose,
  collection,
  collections,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const handleMerge = async () => {
    setLoading(true);
    await form
      .validateFields()
      .then(async (values) => {
        try {
          const { data } = await Api.get(`/collections/${collection.id}/merge`, {
            merge_id: values.merge_id
          });
          setLoading(false);
          // return;
          if (data && data.data && data.data.id) {
            notification.success({
              message: "Merged",
            });
            form.resetFields();
            onClose("success");
            history.push(`/collections/${data.data.id}`);
          }
        } catch (e: any) {
          console.log("error", e.response.data.messages[0]);
          setLoading(false);
          const errorMsg =
            (e &&
              e.response &&
              e.response.data &&
              e.response.data.messages &&
              e.response.data.messages[0]) ||
            "Failed to merge collection";
          notification.error({
            message: "Failed to merge",
            description: errorMsg,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setLoading(false);
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <Modal
      title="Merge Collection"
      style={{ textAlign: "center", fontWeight: "bold" }}
      visible={isOpen}
      onCancel={() => {
        onClose("cancel");
        form.resetFields();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onClose("cancel");
            form.resetFields();
          }}
        >
          No, Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleMerge}
        >
          Yes, merge
        </Button>,
      ]}
    >
      <InviteWrapper>
        <FormWrapper>
          <Col>
            <Form
              {...layout}
              // initialValues={{ ...user }}
              form={form}
              // onFinish={handleSettingsUpdate}
              name="resetPassword"
              layout="vertical"
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Source Collection"
                    name="collection1"
                    labelAlign="left"
                    initialValue={
                      collection &&
                      collection.attributes &&
                      collection.attributes.name
                    }
                  >
                    <Input
                      disabled
                      prefix={
                        <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Target Collection"
                    name="merge_id"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Target collection is required",
                      },
                    ]}
                  >
                    <Select>
                      {collections.map((s) => (
                        <Select.Option key={s.id} value={s.id}>
                          {s.attributes.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </FormWrapper>
      </InviteWrapper>
    </Modal>
  );
};
export default MergeCollectionModal;
