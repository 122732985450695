import React from 'react';
import { Result, Button } from 'antd';

const ErrorResult = () => {
    return (<Result
    status="warning"
    title="There are some problems with your operation."
    extra={
      <Button type="primary" key="console">
        Refresh
      </Button>
    }/>)
}

export default ErrorResult;