import React from "react";
import { DashboardContainer } from "components/Container/Container";
import { useLocation } from "react-router-dom";
import * as qs from "query-string";
import { Button, Result } from "antd";
function TooManyIPsView() {
  const location = useLocation();
  const queries = qs.parse(location.search);
  const description = queries.message || "We have detected too many IPs. Please check your email to confirm your identity.======";
  return (
    <DashboardContainer>
      <Result
        status="error"
        title="Error"
        subTitle={description}
        extra={[
          <Button type="primary" key="console">
            <a href="/login">Login</a>
          </Button>,
        ]}
      />
    </DashboardContainer>
  );
}

export default TooManyIPsView;
