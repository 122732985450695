const config = {
  locale: window.localStorage && window.localStorage.getItem ? window.localStorage.getItem('locale') : 'en',
  IS_CONSOLE_ALLOWED: true,
  stripe_pub_key: process.env.REACT_APP_STRIPE_API_KEY,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // API_BASE_URL: "http://localhost:3002/api",
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  version: '2024-06-28-1005',
  socketURL: process.env.REACT_APP_SOCKET_URL || 'wss://socket-stag.specieslistpro.com',
  // API_BASE_URL: "http://localhost:8080",
  MAX_TICKET_CHARACTER_LIMIT: process.env.MAX_TICKET_CHARACTER_LIMIT || 250,
  MIN_TICKET_CHARACTER_LIMIT: process.env.MIN_TICKET_CHARACTER_LIMIT || 50,
};
if (process.env.NODE_ENV === 'production') {
  config.IS_CONSOLE_ALLOWED = false;
}
// console.log(process.env); // eslint-disable-line
// if (process.env.REACT_APP_SERVER_TEST) {
  
// }

export default config;
