import React, { useState } from "react";
import moment from "moment";
import { Table, Tag, Button, Row, notification, Popconfirm, Tooltip } from "antd";
import styled from "styled-components";
import DropdownActions from "../DropdownActions/DropdownActions";
import { DeleteOutlined, DownloadOutlined, EditOutlined, ShareAltOutlined } from "@ant-design/icons";
import FontAwesomeIcon from "components/Common/FontAwesomeIcon";
import { ThemeConfig } from "theme";
import { TableDarkHeader } from "components/Common/TableStyles";
import { Link ,useHistory } from "react-router-dom";
import { deleteCollection } from "api/helper";
import CloneCollectionModal from "components/Models/CloneCollectionModal";
import CollaborateCollectionModal from "components/Models/CollaborateCollectionModal";
import MergeCollectionModal from "components/Models/MergeCollectionModal";
import { refresh } from "helper";
interface Props {
  collections: any;
  type: string;
  pagination?: any;
  tableLoading?: boolean;
  pageLimit?: number;
  handleTableChange: (pagination, filter, sorter) => void;
  onEditCollection: (collection) => void;
//   onPauseCollection: (collection) => void;
  onDeleteCollection: (collection) => void;
  refetch?: () => void;
}
const CollectionTableWrapper = styled.div`
& .ant-btn {
    margin: 2px;
}
.ant-table-cell {
  text-align: center;
}
`;

const CollectionTable = ({
  collections,
  pagination,
  tableLoading,
  pageLimit,
  refetch,
  type,
  handleTableChange,
  onEditCollection,
//   onPauseCollection,
  onDeleteCollection,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cloneCollection, setCloneCollection] = useState<any>(null);
  const [collaborateCollection, setCollaborateCollection] = useState<any>(null);
  const [mergeCollection, setMergeCollection] = useState<any>(null);
  let history = useHistory();
  const handleDeleteCollection = async (id) => {
    setLoading(true);
    const delRes = await deleteCollection(id);
    setLoading(false);
    if (delRes && delRes.error) {
      notification.error({
        message: "Delete Failed",
        description: delRes.error,
      });
      // setLoading(false);
    } else {
      notification.success({
        message: "Deleted",
        description: delRes.data || "Success",
      });
      // refetch && refetch();
      refresh();
      // history.push("/collections");
    }
  };
  const handleMergeCollection = async (id) => {
    setLoading(true);
    const delRes = await deleteCollection(id);
    setLoading(false);
    if (delRes && delRes.error) {
      notification.error({
        message: "Delete Failed",
        description: delRes.error,
      });
      // setLoading(false);
    } else {
      notification.success({
        message: "Deleted",
        description: delRes.data || "Success",
      });
      refetch && refetch();
      // history.push("/collections");
    }
  };
  const columns = [
    {
      key: "name",
      title: "Name",
      width: 400,
      sorter: (a, b) => a.attributes.name.length - b.attributes.name.length,
      render: (record) => (
        <Link
          to={{
            pathname: `/collections/${record.id}`,
            // state: {
            //   collection: record,
            // }
          }}
        >
          {/* <HomeOutlined /> */}
          <span style={{ textTransform: "capitalize" }}>
            {record.attributes.name}
          </span>
        </Link>
      ),
    },
    // {
    //   key: "createdAt",
    //   title: "Created At",
    //   render: (record) => moment(record.createdAt).format("DD MMM, hh:mm a"),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        const needsMapping = record.attributes && record.attributes.is_user_upload && !record.attributes.mapped;
        return <>
        <Tooltip placement="bottomLeft" title={needsMapping ? "Complete mapping to use this option" : "Invite other users to collaborate"}>
          <Button
            type="primary"
            onClick={() => setCollaborateCollection(record)}
            icon={<ShareAltOutlined />}
            size="small"
            disabled={needsMapping}
          >
            Collaborate
          </Button>
          </Tooltip>
          <Tooltip placement="bottomLeft" title={needsMapping ? "Complete mapping to use this option" : "Merge collection"}>
          <Button
            type="primary"
            onClick={() => setMergeCollection(record)}
            icon={<FontAwesomeIcon name="compress" sidebar />}
            size="small"
            disabled={needsMapping}
          >
            Merge
          </Button>
          </Tooltip>
          <Tooltip placement="bottomLeft" title={needsMapping ? "Complete mapping to use this option" : "Duplicate collection"}>
          <Button
            type="link"
            onClick={() => setCloneCollection(record)}
            icon={
              <FontAwesomeIcon
                name="clone"
                sidebar
                color={ThemeConfig.colors.skyBlue}
              />
            }
            disabled={needsMapping}
            size="small"
          >
            Duplicate
          </Button>
          </Tooltip>
          <Button
            type="primary"
            href={`/collections/${record.id}`}
            icon={<EditOutlined />}
            size="small"
          >
            Edit
          </Button>
          <Popconfirm
            placement="top"
            title={"Are you sure you wish to delete this collection?"}
            onConfirm={() => handleDeleteCollection(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              size="small"
              // onClick={() => handleDeleteCollection(record.id)}
            >
              Delete
            </Button>
          </Popconfirm>
          {/* <DropdownActions
            menuItem={[
              {
                key: "edit",
                title: "Edit Collection",
                onClick: () => onEditCollection(record),
              },
              {
                key: "delete",
                title: "Delete Collection",
                onClick: () => onDeleteCollection(record),
              },
            ]}
          /> */}
        </>
      },
    },
  ];

  return (
    <CollectionTableWrapper>
      {/* <TableDarkHeader>Plants</TableDarkHeader> */}
      <TableDarkHeader>
        {type === "plant" ? "Plant" : "Wildlife"}
      </TableDarkHeader>
      <Table
        scroll={{ x: true }}
        loading={tableLoading || loading}
        dataSource={collections}
        // onChange={handleTableChange}
        //   pagination={{
        //     pageSize: pageLimit,
        //     total: pagination.total,
        //     showTotal: (total, range) =>
        //       `${range[0]}-${range[1]} of ${total} items`,
        //     // onChange: handlePageChange,
        //   }}
        pagination={{
          position: ["bottomCenter"],
        }}
        rowKey={(record) => record.id}
        columns={columns}
      />
      {!!cloneCollection && (
        <CloneCollectionModal
          isOpen={!!cloneCollection}
          onClose={(type) => {
            if(type === 'success') {
              refetch && refetch();
            }
            setCloneCollection(null);
            
          }}
          collection={cloneCollection}
        />
      )}
      {!!collaborateCollection && (
        <CollaborateCollectionModal
          isOpen={!!collaborateCollection}
          onClose={(type) => {
            if(type === 'success') {
              // refetch && refetch();
            }
            setCollaborateCollection(null);
            
          }}
          collection={collaborateCollection}
        />
      )}
      {!!mergeCollection && (
        <MergeCollectionModal
          isOpen={!!mergeCollection}
          onClose={(type) => {
            if(type === 'success') {
              console.log('clone collect', mergeCollection);
              // refetch && refetch();
            }
            setMergeCollection(null);
            
          }}
          collections={collections}
          collection={mergeCollection}
        />
      )}
    </CollectionTableWrapper>
  );
};

export default CollectionTable;
