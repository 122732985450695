import { ThemeConfig } from "theme";
import styled from "styled-components";

export const TableDarkHeader = styled.div`
  width: 100%;
  background: ${ThemeConfig.colors.darkBlue};
  color: ${ThemeConfig.colors.text};
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
`;
