import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  message,
  Alert,
  Row,
  Col,
  List,
  Avatar,
  Skeleton,
  Divider,
  Card,
  notification,
} from "antd";
import Api from "api";
import styled from "styled-components";
import { ThemeConfig } from "theme";
import SearchInput from "components/Common/SearchInput";
const ACCEPT_UPDATE_STATE = "accept_update";
const KEEP_STATE = "keep_original";
const FIND_REPLACE_STATE = "find_replace";

const StyledCard = styled(Card)`
  .selectedState {
    border: 2px solid ${ThemeConfig.colors.darkBlue};
  }
  .autocompleteSearchInput {
    font-weight: 400;
  }
`;
const CollectionErrorReviewModal = ({ isOpen, onClose, collection }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorFixItems, setErrorFixItems] = useState<any>([]);
  useEffect(() => {
    if (
      collection &&
      !collection.error_fixed &&
      collection.errorItems &&
      collection.errorItems.length > 0
    ) {
      setErrorFixItems(
        collection.errorItems.map((_item) => ({
          item_id: _item._id,
          term_id: null,
          state: _item.document?.no_match ? KEEP_STATE : ACCEPT_UPDATE_STATE,
          no_match: !!_item.document?.no_match,
          _item,
        }))
      );
    }
  }, [collection]);
  const handleErrorStateChange = (item, state) => {
    const newErrorItems = errorFixItems.map((_item) => {
      if (_item.item_id === item.item_id) {
        _item.state = state;
      }
      return _item;
    });
    setErrorFixItems(newErrorItems);
  };
  const validateErrorItems = () => {
    let valid = true;
    errorFixItems.map((item) => {
      if (
        item.state == "" ||
        (item.state == FIND_REPLACE_STATE && !item.term_id)
      ) {
        valid = false;
      }
    });
    if (!valid) {
      message.error({
        content:
          "There are some errors in your items. Please check all items are properly set",
        duration: 2,
      });
    }
    return valid;
  };

  const onSave = async (values) => {
    setLoading(true);
    if (!validateErrorItems()) {
      setLoading(false);
      return;
    }
    try {
      const { data } = await Api.post(
        `/collections/${collection.id}/fix_error_items`,
        {
          items: errorFixItems,
        }
      );
      setLoading(false);
      if (data && data.messages) {
        notification.success({
          message: "Error Fix Success",
          description: data.messages[0],
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to fix error",
        });
      }
      onClose('refetch');
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to map columns";
      notification.error({
        message: "Failed",
        description: errorMsg,
      });
      // setErr(errorMsg);
      // setTimeout(() => {
      //   setErr("");
      // }, 3000);
    }
  };

  const onDiscard = async (values) => {
    setLoading(true);
    // return;
    try {
      const { data } = await Api.post(
        `/collections/${collection.id}/discard_error_items`,
        {
          items: errorFixItems,
        }
      );
      console.log('discard res', data);
      setLoading(false);
      if (data) {
        notification.success({
          message: "Success",
          description: "Error Discarded",
        });
        onClose("refetch");
        // history.push(`/collections/${data.data.id}`);
      } else {
        notification.error({
          message: "Error",
          description: "Failed to discard error",
        });
      }
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to map columns";
      notification.error({
        message: "Failed",
        description: errorMsg,
      });
    }
  };
  const capitalize = (string) => {
    if(!string) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleSelectReplace = (item, term_id) => {
      const newErrorItems = errorFixItems.map((_item) => {
        if (_item.item_id === item.item_id) {
          _item.term_id = term_id;
        }
        return _item;
      });
      setErrorFixItems(newErrorItems);
  }
  const renderSingleErrorItem = (singleItem, rowIndex) => {
    return (
      // <List.Item>
      <StyledCard
        style={{ margin: "10px 5px" }}
        bodyStyle={{ padding: 10 }}
        key={rowIndex}
      >
        <Row>
          <span style={{ textAlign: "left", fontSize: 14, fontWeight: 400 }}>
            {!singleItem._item.document.no_match ? (
              <span>
                Record {rowIndex + 1} of {collection.errorItems.length}:{" "}
                {capitalize(
                  singleItem._item.document[`${collection.type}_name`]
                )}{" "}
                was updated to{" "}
                {capitalize(
                  singleItem._item.document.new_name
                    ? singleItem._item.document.new_name + "(new name)"
                    : singleItem._item.document.exact_name
                    ? singleItem._item.document.exact_name + "(exact name)"
                    : singleItem._item.document.closest_name + "(closest match)"
                )}
              </span>
            ) : (
              <span>
                Record {rowIndex + 1} of {collection.errorItems.length}:{" "}
                {capitalize(
                  singleItem._item.document[`${collection.type}_name`]
                )}{" "}
                does not match any data
              </span>
            )}
          </span>
        </Row>
        {singleItem._item?.document?.description && (
          <Row>
            <span style={{ textAlign: "left", fontSize: 14, fontWeight: 400, color: "#808080" }}> {singleItem._item.document.description}</span>
          </Row>
        )}
        {!singleItem._item.document.exact_name && (
          <Row>
            <Col style={{ padding: 5 }}>
              <Button
                // className="selectedState"
                type={singleItem.state === ACCEPT_UPDATE_STATE ? "primary" : "dashed"}
                className={
                  singleItem.state === ACCEPT_UPDATE_STATE
                    ? "selectedState"
                    : ""
                }
                // type="primary"
                style={{ fontSize: 12, width: "100%" }}
                disabled={singleItem._item.document.no_match}
                onClick={() => {
                  handleErrorStateChange(singleItem, ACCEPT_UPDATE_STATE);
                }}
              >
                ACCEPT UPDATE
              </Button>
            </Col>
            <Col style={{ padding: 5 }}>
              <Button
                type={singleItem.state === KEEP_STATE ? "primary" : "dashed"}
                className={
                  singleItem.state === KEEP_STATE ? "selectedState" : ""
                }
                style={{ width: 250, fontSize: 12 }}
                onClick={() => {
                  handleErrorStateChange(singleItem, KEEP_STATE);
                }}
              >
                KEEP {singleItem._item.document[`${collection.type}_name`]}
              </Button>
            </Col>
            <Col style={{ padding: 5 }}>
              <Row gutter={4}>
                <Col xs={12}>
                  <Button
                    type={singleItem.state === FIND_REPLACE_STATE ? "primary" : "dashed"}
                    className={
                      singleItem.state === FIND_REPLACE_STATE
                        ? "selectedState"
                        : ""
                    }
                    style={{ fontSize: 12, width: "100%" }}
                    disabled={singleItem._item.document.no_match}
                    onClick={() => {
                      handleErrorStateChange(singleItem, FIND_REPLACE_STATE);
                    }}
                  >
                    FIND & REPLACE
                  </Button>
                </Col>
                <Col xs={12} style={{ width: 200 }}>
                  <SearchInput
                    type={collection.type}
                    collectionId={collection.id}
                    placeholder="Search..."
                    onSelect={(term_id) => {
                      handleSelectReplace(singleItem, term_id);
                      handleErrorStateChange(singleItem, FIND_REPLACE_STATE);
                    }}
                    searchKey="all"
                    // database={database}
                    excludeIds={[]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </StyledCard>
      // </List.Item>
    );
  };
  if (!collection) {
    return null;
  }
  
  return (
    <Modal
      title="Updated Results:"
      style={{ textAlign: "center", fontWeight: "bold" }}
      bodyStyle={{
        textAlign: "left",
        padding: 0,
        maxHeight: "calc(100vh - 150px)",
        overflowY: "auto",
        // padding: "10px 24px",
      }}
      visible={isOpen}
      width={960}
      onCancel={() => onClose("cancel")}
      footer={[
        <Button loading={loading} key="submit" type="primary" onClick={onSave}>
          Accept All
        </Button>,
        <Button key="review_later" onClick={() => onClose("cancel")}>
          Review Later
        </Button>,
        <Button key="back" onClick={onDiscard}>
          Discard
        </Button>,
      ]}
    >
      {/* <List dataSource={errorFixItems} renderItem={renderSingleErrorItem} /> */}
      {errorFixItems.map(renderSingleErrorItem)}
    </Modal>
  );
};
export default CollectionErrorReviewModal;
