import React, { useState } from "react";
import { Modal, Button, Input, message, Alert, Form, Col, Row } from "antd";
import Api from "api";
import styled from "styled-components";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { ThemeConfig } from "theme";
const InviteWrapper = styled.div`
  // margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const InviteAUserModal = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const [body, setBody] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const handleTicket = async () => {
    setLoading(true);
    await form.validateFields().then(async values => {
      try {
        const { data } = await Api.post("/invitations", {
          ...values
        });
        setLoading(false);
        if (data && data.messages) {
          message.success({ content: data.messages[0], duration: 3 });
          form.resetFields();
          onClose("ok");
        }
      } catch (e: any) {
        console.log("error", e.response.data.messages[0]);
        setLoading(false);
        const errorMsg =
          (e &&
            e.response &&
            e.response.data &&
            e.response.data.messages &&
            e.response.data.messages[0]) ||
          "Failed to send invitation";
        setErr(errorMsg);
        setTimeout(() => {
          setErr("");
        }, 3000);
      }
    }).catch(e => {
      console.log(e);
    })
    setLoading(false);
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <Modal
      title="Invite other users"
      style={{ textAlign: "center", fontWeight: "bold" }}
      visible={isOpen}
      onCancel={() => {
        onClose("cancel");
        form.resetFields();
      }}
      footer={[
        <Button key="back" onClick={() => {
          onClose("cancel");
          form.resetFields();
        }}>
          Close
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleTicket}
        >
          Invite a user
        </Button>,
      ]}
    >
      <InviteWrapper>
        <FormWrapper>
          <Col>
            {err && err.length && (
              <Alert message={err} type="error" style={{ marginBottom: 10 }} />
            )}
            <Form
              {...layout}
              // initialValues={{ ...user }}
              form={form}
              // onFinish={handleSettingsUpdate}
              name="resetPassword"
              layout="vertical"
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Enter a valid email" },
                    ]}
                  >
                    <Input
                      prefix={
                        <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Enter your Name" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var validationRegex = /[^a-z A-Z]/;
                          if (!value || !validationRegex.test(value)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Please enter valid name")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Name"
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                  {/* <Form.Item label="Coupon" name="coupon" labelAlign="left">
                  <Input
                    placeholder="Coupon"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    // onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item> */}
                </Col>
              </Row>
            </Form>
          </Col>
        </FormWrapper>
      </InviteWrapper>
    </Modal>
  );
};
export default InviteAUserModal;

