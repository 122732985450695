import React, { useState } from "react";
import styled from "styled-components";
import { setToken } from "../../helper";
import {
  Form,
  Input,
  Button,
  Col,
  Result,
  message,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import Api from "api";
import { passwordValidatorAnt } from "helper/passwordValidatorAnt";

const LoginWrapper = styled.div`
  margin-top: 70px;
  min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
    // margin: 10px;
  }
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-form-item {
    // margin-bottom: 0px;
  }
  .submit {
    padding: 0 30px !important;
    color: #fff !important;
    background: #08243c !important;
    border: none !important;
    line-height: 35px !important;
    height: 35px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    width: auto !important;
    margin: 16px 0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;


interface Props {
  // resetToken: any;
}

const ChangePasswordForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>();
  const [result, setResult] = useState<string>();
  const handleChangePassword = async (values) => {
    setLoading(true);
    try {
      const { status } = await Api.post("/account/update_password", {
        password: values.current_password,
        new_password: values.password,
        // token: props.resetToken,
      });
      setLoading(false);
      message.success({ content: 'Password changed successfully', duration: 3 });
      form.resetFields();
      // if (data && data.token) {
      //   await setToken(data.token.split("JWT ")[1]);
      //   setResult("Password reset successfully");
      // }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to Change password";
      console.log(errorMsg);
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  if (result && result.length) {
    return (
      <LoginWrapper>
        <Result
          status="success"
          title="Password reset success!"
          subTitle={result}
          extra={[
            <Button type="primary" key="console">
              <a href="/">Ok</a>
            </Button>,
          ]}
        />
      </LoginWrapper>
    );
  }
  return (
    <LoginWrapper>
      <FormWrapper>
        <Col lg={{ span: 8, offset: 8 }}>
          <h4 className="mb-3" style={{ color: "#494848" }}>
            CHANGE YOUR PASSWORD
          </h4>
          <Form
            {...layout}
            form={form}
            onFinish={handleChangePassword}
            name="resetPassword"
            layout="vertical"
            autoComplete="off"
          >
            <Col span={24}>
              <Form.Item
                label="Current Password"
                name="current_password"
                labelAlign="left"
                rules={[
                  { required: true, message: "Enter your current password" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Current Password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="New Password (6 characters minimum)"
                name="password"
                labelAlign="left"
                rules={[
                  // { required: true, message: "Enter your password" },
                  passwordValidatorAnt,
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="New Password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords should match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </Col>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit"
                // onClick={login}
                loading={loading}
              >
                Change My Password
              </Button>
            </div>
          </Form>
        </Col>
      </FormWrapper>
    </LoginWrapper>
  );
};

export default ChangePasswordForm;
