import React, { useEffect, useState } from "react";
import { Input, Button, notification } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { addItemNote } from "api/helper";

const ItemNote = ({ collectionId, itemId, note, setTableLoading, type }) => {
  const [itemNote, setItemNote] = useState<any>({});
  const saveItemNote = async () => {
    setTableLoading(true);
    const delRes: any = await addItemNote(collectionId, itemId, itemNote, type);
    setTableLoading(false);
    if (delRes && delRes.error) {
      notification.error({
        message: "Failed",
        description: delRes.error,
      });
    } else {
      notification.success({
        message: "Success",
        description: delRes?.data?.messages[0] || "Success",
      });
    }
  };
  return (
    <>
      <Input.TextArea
        defaultValue={note}
        onChange={(e) => {
          setItemNote(e.target.value);
        }}
      />
      <br />
      <Button
        type="primary"
        icon={<SaveOutlined />}
        size="small"
        style={{ marginTop: 5 }}
        onClick={saveItemNote}
      >
        Update
      </Button>
    </>
  );
};

export default ItemNote;