import React, { createContext, useContext, useState } from 'react';

export const UserContext = createContext<any>(null);

const UserProvider = ({ children }) => {
  const [user, _setUser] = useState<any>(null);
  const setUser = (u) => {
    _setUser((prevState) => {
      return {...prevState, ...u};
    });
  }
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};

export default UserProvider;
