import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  message,
  Alert,
  Form,
  Col,
  Row,
  notification,
  Spin,
} from "antd";
import Api from "api";
import styled from "styled-components";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { useStripe } from "@stripe/react-stripe-js";
import { ThemeConfig } from "theme";
import { getCheckoutSessionForCollection } from "api/helper";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
const DownloadWrapper = styled.div`
  // margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .packageDescription {
    font-weight: 400 !important;
  }
  .ant-modal-body {
    padding: 5px !important;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const CollectionDownloadModal = ({ isOpen, onClose, collectionId }) => {
  const [form] = Form.useForm();
  const [body, setBody] = useState<string>("");
  const stripe = useStripe();
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>("");
  const handlePurchaseClick = async (product) => {
    setLoading(true);
    try {
      const checkoutSession = await getCheckoutSessionForCollection(collectionId);
      if (checkoutSession.data && checkoutSession.data.session_id) {
        setLoading(true);
        stripe?.redirectToCheckout({
          sessionId: checkoutSession.data.session_id,
        });
      } else {
        setLoading(false);
        notification.error({
          message: "Purchase failed",
          description:
            (checkoutSession && checkoutSession.error) ||
            "Failed to generate checkout session",
        });
      }
    } catch (e: any) {
      setLoading(false);
      notification.error({
        message: "Purchase failed",
        description: e.toString(),
      });
    }
  };

  return (
    <Modal
      title="Export"
      width={800}
      style={{ textAlign: "center" }}
      bodyStyle={{ padding: 10 }}
      visible={isOpen}
      onCancel={() => {
        onClose("cancel");
        // form.resetFields();
      }}
      footer={
        <Row style={{ justifyContent: "center" }}>
          <Button
            key="back"
            type="text"
            onClick={() => {
              onClose("cancel");
              // form.resetFields();
            }}
          >
            No Thanks
          </Button>
        </Row>
      }
    >
      <Spin spinning={!stripe}>
        <DownloadWrapper>
          <FormWrapper>
            <Row>
              {/* {err && err.length && (
              <Alert message={err} type="error" style={{ marginBottom: 10 }} />
            )} */}
              <Col span={12}>
                <h3>One Time Subscription</h3>
                <p className="packageDescription">
                  You can export this list for $9.99. If you make changes to the
                  list,you will be charged another $9.99 to export it again.
                </p>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={handlePurchaseClick}
                >
                  Purchase
                </Button>
              </Col>
              <Col span={12}>
                <h3>Subscription</h3>
                <p className="packageDescription">
                  You can export list for all time. You will have to choose
                  Enterprise edition and Professional Edition
                </p>
                <Button type="primary" href="/user/billing">
                  Upgrade
                </Button>
              </Col>
            </Row>
          </FormWrapper>
        </DownloadWrapper>
      </Spin>
    </Modal>
  );
};
export default CollectionDownloadModal;

