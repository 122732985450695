import { Layout as AntdLayout } from "antd";
import styled from "styled-components";

export const StyledHeader = styled(AntdLayout.Header)`
  height: 64px;
  color: #111;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
  background: #0d2942;
`;

export const StyledHeaderNoAuth = styled(AntdLayout.Header)`
  color: #fff;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 999;
  background: #0d2942;
  padding: 10px !important;
`;
export const StyledHeaderAuth = styled(AntdLayout.Header)`
  color: #fff;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding-left: 30px;
  background: #0d2942;

  .headerMenuDropdown .ant-menu-submenu::after {
    border-bottom: none !important;
  }
`;