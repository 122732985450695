import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeConfig } from 'theme';
import { capitalize } from 'lodash';
import {
  Form,
  Input,
  Button,
  Layout as AntdLayout,
  Col,
  Row,
  Select,
  message,
  Result,
} from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import Api from 'api';
import { useStripe } from "@stripe/react-stripe-js";
import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import { passwordValidatorAnt } from 'helper/passwordValidatorAnt';
import { typeMapping } from 'helper/securityAnswerValidator';

const { Option } = Select;

const LoginWrapper = styled.div`
  overflow: scroll;
  width: 100vw;
  margin-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
  }
  .ant-form-item-control {
    padding: 5px;
  }
  .ant-form-item {
    // margin-bottom: 15px;
  }
  .ant-form-item-label {
    // padding: 0px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;


interface Props {}

const LoginForm = (props: Props) => {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const [loading, setLoading] = useState<boolean>(true);
  const [plan, setPlan] = useState<string>('free');
  const [result, setResult] = useState<string>();
  const location = useLocation();
  
  const handlePremiumSession = async (sessionId) => {
    if(!stripe) {
      return message.error('Stripe redirect failed');
    }
    stripe?.redirectToCheckout({
      sessionId
    });
  };
  const handleSubmit = async (values) => {
     setLoading(true);
    const finalValues = { ...values }; //TODO: adding only 2 questions because current SLP has only 2
    if(plan) {
      finalValues.signed_up_plan = capitalize(plan);
    }
    delete finalValues.confirmPassword;
    delete finalValues.plan;
     try {
       const { data } = await Api.post("/auth/signup", finalValues);
       if (data && data.success && data.session_id){
         setResult("You are being redirected to Stripe to complete your payment");
          handlePremiumSession(data.session_id);
       } else {
        setResult(data.messages.join(". "));
       }
       setLoading(false);
     } catch(e:any) {
      //  setToken("123");
      console.log(e);
       setLoading(false);
       const errorMsg =
         (e &&
           e.response &&
           e.response.data &&
           e.response.data.messages &&
           e.response.data.messages[0]) ||
         "Failed to Register. Try again later";
       console.log(e && e.response && e.response.data && e.response.data.messages && e.response.data.messages[0]);
       message.error({content: errorMsg, duration: 3});
     }
   };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
     const queries: any = qs.parse(location.search);
     if (queries && queries.plan && ["enterprise", "professional"].includes((queries.plan || '').toLowerCase())) {
        setPlan(queries.plan);
     }
     setLoading(false);
  }, []);
  if(result && result.length) {
    return (
      <AntdLayout>
        <HeaderNoAuth />
        <LoginWrapper>
          <Result
            status="success"
            title="Signup Success!"
            subTitle={result}
            extra={[
              <Button type="primary" key="console">
                <a href="/login">SignIn</a>
              </Button>,
            ]}
          />
        </LoginWrapper>
      </AntdLayout>
    );
  }
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <LoginWrapper>
        <FormWrapper>
          <Col lg={{ span: 8, offset: 8 }}>
            <h4>PLEASE REGISTER YOUR {plan} ACCOUNT</h4>
            <Form
              {...layout}
              form={form}
              autoComplete="off"
              name="login"
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Row>
                <Col span={12} className="halfInput">
                  <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Enter your Name" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var validationRegex = /[^a-z A-Z]/;

                          if (!value || !validationRegex.test(value)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Please enter valid name")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Name"
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="halfInput">
                  <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Enter a valid email" },
                    ]}
                  >
                    <Input
                      prefix={
                        <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                      // onPressEnter={signup}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="halfInput">
                  <Form.Item
                    label="Password"
                    name="password"
                    labelAlign="left"
                    rules={[
                      // { required: true, message: "Enter your password" },
                      passwordValidatorAnt,
                    ]}
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Password"
                      // onPressEnter={login}
                      // onChange={(e) =>
                      //   setPassword(e.target.value)
                      // }
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="halfInput">
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords should match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Question 1"
                    name="security_question1"
                    labelAlign="left"
                    rules={[{ required: true, message: "Enter question 1" }]}
                  >
                    <Select
                      placeholder="What was the house number and street name you lived in as a child?"
                      // onChange={(value: string) => {
                      //   setQuestion1(value);
                      // }}
                    >
                      <Option value="What was the house number and street name you lived in as a child?">
                        What was the house number and street name you lived in
                        as a child?
                      </Option>
                      <Option value="What were the last four digits of your childhood telephone number?">
                        What were the last four digits of your childhood
                        telephone number?
                      </Option>
                      <Option value="What primary school did you attend?">
                        What primary school did you attend?
                      </Option>
                      <Option value="In what town or city was your first full time job?">
                        In what town or city was your first full time job?
                      </Option>
                      <Option value="In what town or city did you meet your spouse/partner?">
                        In what town or city did you meet your spouse/partner?
                      </Option>
                      <Option value="What is the middle name of your oldest child?">
                        What is the middle name of your oldest child?
                      </Option>
                      <Option value="What are the last five digits of your driver's licence number?">
                        What are the last five digits of your driver's licence
                        number?
                      </Option>
                      <Option value="What is your grandmother's (on your mother's side) maiden name?">
                        What is your grandmother's (on your mother's side)
                        maiden name?
                      </Option>
                      <Option value="What is your spouse or partner's mother's maiden name?">
                        What is your spouse or partner's mother's maiden name?
                      </Option>
                      <Option value="In what town or city did your mother and father meet?">
                        In what town or city did your mother and father meet?
                      </Option>
                      <Option value="What time of the day were you born? (hh:mm)">
                        What time of the day were you born? (hh:mm)
                      </Option>
                      <Option value="What time of the day was your first child born? (hh:mm)">
                        What time of the day was your first child born? (hh:mm)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Answer 1"
                    name="answer1"
                    labelAlign="left"
                    dependencies={["security_question1"]}
                    rules={[
                      {
                        required: true,
                        message: "Enter answer 1",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const questionValue =
                            getFieldValue("security_question1");

                          if (!questionValue || !value) {
                            return Promise.resolve();
                          }
                          const validationRegex =
                            typeMapping[questionValue].regex || false;
                          const validationTruth =
                            typeMapping[questionValue].truth;
                          if (!validationRegex) {
                            return Promise.resolve();
                          }
                          if (
                            validationTruth
                              ? validationRegex.test(value)
                              : !validationRegex.test(value)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(typeMapping[questionValue].message)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Answer 1"
                      // prefix={
                      //   <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      // }
                      // onPressEnter={login}
                      // onChange={(e) => setAnswer1(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Question 2"
                    name="security_question2"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Enter question 2" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const questionValue =
                            getFieldValue("security_question1");

                          if (!questionValue || !value) {
                            return Promise.resolve();
                          }
                          if(questionValue === value) {
                            return Promise.reject(new Error('Question 2 cannot be same as Question 1'))
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      placeholder="What was the house number and street name you lived in as a child?"
                      // onChange={(value: string) => {
                      //   console.log("changed", value);
                      //   setQuestion2(value);
                      // }}
                    >
                      <Option value="What was the house number and street name you lived in as a child?">
                        What was the house number and street name you lived in
                        as a child?
                      </Option>
                      <Option value="What were the last four digits of your childhood telephone number?">
                        What were the last four digits of your childhood
                        telephone number?
                      </Option>
                      <Option value="What primary school did you attend?">
                        What primary school did you attend?
                      </Option>
                      <Option value="In what town or city was your first full time job?">
                        In what town or city was your first full time job?
                      </Option>
                      <Option value="In what town or city did you meet your spouse/partner?">
                        In what town or city did you meet your spouse/partner?
                      </Option>
                      <Option value="What is the middle name of your oldest child?">
                        What is the middle name of your oldest child?
                      </Option>
                      <Option value="What are the last five digits of your driver's licence number?">
                        What are the last five digits of your driver's licence
                        number?
                      </Option>
                      <Option value="What is your grandmother's (on your mother's side) maiden name?">
                        What is your grandmother's (on your mother's side)
                        maiden name?
                      </Option>
                      <Option value="What is your spouse or partner's mother's maiden name?">
                        What is your spouse or partner's mother's maiden name?
                      </Option>
                      <Option value="In what town or city did your mother and father meet?">
                        In what town or city did your mother and father meet?
                      </Option>
                      <Option value="What time of the day were you born? (hh:mm)">
                        What time of the day were you born? (hh:mm)
                      </Option>
                      <Option value="What time of the day was your first child born? (hh:mm)">
                        What time of the day was your first child born? (hh:mm)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Answer 2"
                    name="answer2"
                    labelAlign="left"
                    rules={[
                      { required: true, message: "Enter answer 2" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const questionValue =
                            getFieldValue("security_question2");

                          if (!questionValue || !value) {
                            return Promise.resolve();
                          }
                          const validationRegex =
                            typeMapping[questionValue].regex || false;
                          const validationTruth =
                            typeMapping[questionValue].truth;
                          if (!validationRegex) {
                            return Promise.resolve();
                          }
                          if (
                            validationTruth
                              ? validationRegex.test(value)
                              : !validationRegex.test(value)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(typeMapping[questionValue].message)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Answer 2"
                      // prefix={
                      //   <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      // }
                      // onPressEnter={login}
                      // onChange={(e) => setAnswer2(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {plan === "free" && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="If you have a (optional) coupon code for an extended free trial, enter it here"
                      name="coupon"
                      labelAlign="left"
                      // rules={[{ required: true, message: "" }]}
                    >
                      <Input
                        placeholder="Coupon"
                        // prefix={
                        //   <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        // }
                        // onPressEnter={login}
                        // onChange={(e) => setCoupon(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {/* <div style={{ textAlign: 'right', marginBottom: '1em' }}>
              <ForgotPassword
                onClick={() => {
                  // setShowForgotPwModal(true);
                  history.push('forgotpassword');
                }}
              >
                Forgot your password?
              </ForgotPassword>
            </div> */}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: "100%",
                    background: ThemeConfig.colors.skyBlue,
                    marginTop: 10,
                  }}
                >
                  Signup
                </Button>
              </div>

              <div
                className="mtb10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 14,
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Already have an account? Login{" "}
                <a
                  href="/login"
                  style={{
                    paddingLeft: 5,
                    fontSize: 16,
                    fontWeight: 400,
                    color: ThemeConfig.colors.green,
                  }}
                >
                  here
                </a>
              </div>
            </Form>
          </Col>
        </FormWrapper>
      </LoginWrapper>
    </AntdLayout>
  );
};

export default LoginForm;
