import React, { useEffect, useState } from 'react';
import {  Button, Layout as AntdLayout, message, Result } from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import styled from "styled-components";
import * as qs from "query-string";
import { useLocation } from "react-router-dom";
import Api from 'api';
import { LoadingSpinnerView } from 'components/LoadingSpinner/LoadingSpinner';

interface Props {
  checkoutInfo?: any;
}
const LoginWrapper = styled.div`
  overflow: scroll;
  width: 100vw;
  margin-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  h4 {
    color: #494848;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 30px;
    padding: 0 0 10px;
    letter-spacing: 0.5px;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .checkboxWrapper {
    text-align: center;
  }
  .ant-form-item-control {
    padding: 5px;
  }
  .ant-form-item {
    // margin-bottom: 15px;
  }
  .ant-form-item-label {
    // padding: 0px;
  }
`;
const confirmationMessage =
  "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.";
const SignupCheckoutHandler = (props: Props) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<string>("Payment canceled");
  const { checkoutInfo = 'success' } = props;
  const type =
    checkoutInfo && checkoutInfo.type === "cancel" ? "error" : "success";
  const handleCancelCheckout = async (session_id, user_id) => {
    try {
      const { data } = await Api.get("/subscriptions/checkout_cancelled", {user_id});
      setResult(`Payment Canceled, ${confirmationMessage}`);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to cancel subscription.";
      console.log(
        e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]
      );
      message.error({ content: errorMsg, duration: 3 });
    }
  };
  useEffect(() => {
    if (type === 'error') {
      const queries = qs.parse(location.search);
      if (!queries || !queries.session_id) {
        message.error({ content: "Invalid session id", duration: 5 });
      } else {
        setLoading(false);
        handleCancelCheckout(queries.session_id, queries.user_id);
      }
    } else {
      setLoading(false);
      setResult(confirmationMessage);
    }
  }, [location.search]);
  if (loading) return <LoadingSpinnerView size={80} />;
  
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <LoginWrapper>
        <Result
          status={type}
          title="Payment"
          subTitle={result || ""}
          extra={[
            <Button type="primary" key="console">
              <a href="/login">SignIn</a>
            </Button>,
          ]}
        />
      </LoginWrapper>
    </AntdLayout>
  );
};

export default SignupCheckoutHandler;
