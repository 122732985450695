import Api from "api";

export const getProfile = async () => {
  try {
    const { data } = await Api.get("/account/profile", {});
    return {
      data: {
        id: data.data.id,
        ...data.data.attributes,
      },
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch Profile";
    return {
      error: e.toString(),
    };
  }
};

export const getSubscriptionProducts = async () => {
  try {
    const { data } = await Api.get("/subscription_products", {});
    return {
      data
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch products";
    return {
      error: errorMsg
    };
  }
};

export const getCheckoutSession = async (stripe_price_id) => {
  try {
    const { data } = await Api.post("/subscriptions/checkout_session", { stripe_price_id });
    return {
      data
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch checkout session";
    return {
      error: errorMsg
    };
  }
};

export const getCheckoutSessionForCollection = async (collectionId) => {
  try {
    const { data } = await Api.get(`/payments/checkout_session/${collectionId}`, {});
    return {
      data
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch payment session";
    return {
      error: errorMsg
    };
  }
};
export const getCustomerPortalSession = async () => {
  try {
    const { data } = await Api.get("/subscriptions/customer_portal", {});
    return {
      data
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch customer session";
    return {
      error: errorMsg
    };
  }
};

export const deleteCollection = async (id) => {
  try {
    const { data } = await Api.delete(`/collections/${id}`);
    return {
      data: data && data.messages ? data.messages[0] : '',
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Delete collection item";
    return {
      error: errorMsg
    };
  }
};

export const cloneCollection = async (id, name) => {
  try {
    const { data } = await Api.get(`/collections/${id}/clone`, { name });
    return {
      data: data && data.data ? data.data : {},
    };
  } catch (e: any) {
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Clone collection";
    return {
      error: errorMsg
    };
  }
};

export const addCollectionItem = async (collectionId, params) => {
  try {
    const { data } = await Api.post(`/collections/${collectionId}/add_item`, params);
    return {
      data
    };
  } catch (e: any) {
    // console.log(e);
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch";
    return {
      error: errorMsg
    };
  }
};

export const deleteCollectionItem = async (collectionId, itemId, params) => {
  try {
    // console.log('deleting are params', params);
    const { data } = await Api.delete(`/collections/${collectionId}/item/${itemId}?type=${params.type}`);
    return {
      data
    };
  } catch (e: any) {
    console.log(e);
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch";
    return {
      error: errorMsg
    };
  }
};

export const addItemNote = async (collectionId, item_id, note, type) => {
  // return {
  //   error: 'No api',
  // }
  try {
    const { data } = await Api.post(`/collections/${collectionId}/update_item`, {
      item_id,
      note,
      type
    });
    return {
      data
    };
  } catch (e: any) {
    console.log(e);
    const errorMsg =
      (e &&
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages[0]) ||
      "Failed to Fetch";
    return {
      error: errorMsg
    };
  }
};
export const userActions = {
  merge: "merge",
  duplicate: "duplicate",
  collaborate: "collaborate",
  clone: "clone",
  upload_excel_file: "upload_excel_file",
  download: "download",
};
export const checkUserCan = (user, action) => {
  if (!user) {
    return false;
  }
  console.log('checking', user, action);
  const actionChecks = {
    merge:
      user.status === "enterprise" ||
      user.status === "professional" ||
      user.status === "free_trial",
    duplicate:
      user.status === "enterprise" ||
      user.status === "professional" ||
      user.status === "free_trial",
    collaborate: user.status === "enterprise" || user.status === "free_trial",
    clone:
      user.status === "enterprise" ||
      user.status === "professional" ||
      user.status === "free_trial",
    upload_excel_file:
      user.status === "enterprise" ||
      user.status === "professional" ||
      user.status === "free_trial",
    download:
      user.status === "enterprise" ||
      user.status === "professional" ||
      user.status === "free_trial",
  };

  return !!actionChecks[action];
};
