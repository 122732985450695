import React from "react";
import { Result, Button } from "antd";
import styled from "styled-components";
import config from "config";

export const EnvModeWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  .circle {
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .indicator {
    font-family: sans-serif;
    background: #ea2429;
    color: #fff;
    padding: 6px 7px;
    border-radius: 6px;
    text-transform: uppercase;
  }
  .blink {
    animation: blinker 1.2s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  }
`;
const EnvModeIndicator = () => {
  if (config.environment === "production") {
    return null;
  }
  return (
    <EnvModeWrapper>
      <span className="indicator">
        <div className="circle blink" aria-hidden="true"></div>
        <span>{config.environment}({config.version})</span>
      </span>
    </EnvModeWrapper>
  );
};

export default EnvModeIndicator;
