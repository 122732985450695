import axios from 'axios';
import { getToken, removeToken, removeUserData } from 'helper';
import config from '../config';
export const API_BASE_URL = config.API_BASE_URL;
// set headers
const setHeaders = async () => {
  let token = await getToken();
  if (token) {
    axios.defaults.headers.common = { Authorization: `jwt ${token}` };
  }
};
class CustomError extends Error {
  [x: string]: any;
  constructor(name, { response }) {
    super(name);
    this.name = name || "ValidationError"; 
    this.response = response
  }
}
const checkAndHandleIPError = (e) => {
  try {
    if(e && e.response && e.response.data && e.response.data.messages && e.response.data.messages[0].includes('We have detected too many IP')) {
    removeUserData()
    removeToken();
    window.location.href = `/error_too_many_ip?message=${e.response.data.messages[0]}`;
    return true;
  }
  } catch(e) {
    return false;
  }
}
const checkAndHandleUnauthorizedError = (e) => {
  try {
    if(e && e.response && e.response.data === "Unauthorized") {
    removeUserData()
    removeToken();
    window.location.href = `/login?message=Invalid Session`;
    return true;
  }
  } catch(e) {
    console.log(e);
    return false;
  }
}
const checkAndHandleErrors = (e) => {
  if(checkAndHandleIPError(e)) {
    return new CustomError("IPLimitError", {
          response: { data: {
            messages: ["We have detected too many IPs"],
          }}
    });
  } else if(checkAndHandleUnauthorizedError(e)) {
    return new CustomError("UnauthorizedError", {
          response: { data: {
            messages: ["Invalid sesson"],
          }}
    });
  } else if(e && e.status === 500) {
    return new CustomError("UnauthorizedError", {
          response: { data: {
            messages: ["Internal Server Error"],
          }}
    });
  } else if(e && e.response && e.response.data && e.response.data.messages) {
        return e;
  } else {
    return new CustomError("CustomError", {
          response: { data: {
            messages: [((e && e.response && e.response.data && e.response.data.errors && e.response.data.errors[0] && e.response.data.errors[0].title )) || (e.response?.data?.message) || e.toString() || "Something went wrong"],
          }}
    });
  }
}

// global api
const Api = {
  async get(endpoint, params = {}, options = {}) {
    await setHeaders();
    try {
      const res = await axios.get(`${API_BASE_URL}${endpoint}`, { params: params, ...options });
      return res;
    } catch(e: any) {
      console.log("error", e.response);
      throw checkAndHandleErrors(e);
    }
  },

  async post(endpoint, params, options:any = {}) {
    await setHeaders();
    try {
      const res = await axios.post(`${API_BASE_URL}${endpoint}`, params);
      return res;
      
    } catch(e: any) {
      console.log(e);
     throw checkAndHandleErrors(e);
    }
  },
  async put(endpoint, params) {
    await setHeaders();
    try {
      const res = await axios.put(`${API_BASE_URL}${endpoint}`, params);
      return res;
    } catch(e: any) {
      throw checkAndHandleErrors(e);
    }
  },
  async delete(endpoint) {
    await setHeaders();
    try {
      const res = await axios.delete(`${API_BASE_URL}${endpoint}`);
      return res;
    } catch(e: any) {
      throw checkAndHandleErrors(e);
    }
  },
};

export default Api;