import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Row,
  Select,
  Space,
  Col,
  notification,
  Empty,
  Typography,
  Result,
  Spin,
} from "antd";
import styled from "styled-components";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ThemeConfig } from "theme";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import Api from "api";
import { deleteCollection } from "api/helper";
import { initiateSocket } from "helper/socket";
import { useUser } from "helper/UserContext";
import { getTableScroll } from "helper/tableUtils";

interface Props {
  collectionData?: any;
}

const { Option } = Select;
const CollectionColumnMappingTableWrapper = styled.div`
  .ant-table-cell {
    text-align: center;
  }
  .collectionColumnButton .ant-select-selection-item {
    display: none !important;
  }
  .collectionColumnButton .ant-select-selector::before {
    position: absolute;
    content: "Columns";
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    padding-left: 10px;
    align-items: center;
  }
  .collectionColumnButton .ant-select-selector {
    background: ${ThemeConfig.colors.skyBlue};
    color: #fff;
  }
`;

const CollectionColumnMappingTable = ({ collectionData }: Props) => {
  const [columns, setColumns] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [originalColumns, setOriginalColumns] = useState<any>([]);
  const [columnsMapping, setColumnsMapping] = useState<any>({});
  const [socketWaiting, setSocketWaiting] = useState<boolean>(false);
  const history = useHistory();
  const socketClient: any = useRef();
  const { user } = useUser();
  const ResizableTitle = useCallback(
    (props) => {
      const { onResize, width, children, ...restProps } = props;
      const matchingColumn = children.find((s) => s && typeof s === "string");
      return (
        <th {...restProps}>
          <Typography.Paragraph ellipsis>{children}</Typography.Paragraph>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            // defaultValue={searchBy}
            value={columnsMapping && columnsMapping[matchingColumn]}
            onChange={(v) => {
              // setSearchBy(v);
              setColumnsMapping({
                ...columnsMapping,
                [matchingColumn]: v,
              });
            }}
            style={{ width: "100%" }}
          >
            {originalColumns.map((s) => (
              <Option key={s.value} value={s.value}>
                {s.label}
              </Option>
            ))}
          </Select>
        </th>
      );
    },
    [originalColumns, columnsMapping]
  );
  const onSave = async (values) => {
    if(!Object.keys(columnsMapping).length){
      notification.error({
        message: "Incomplete mapping!",
        description:
          "Before saving, kindly choose at least one option from a dropdown of any column.",
      });
      return;
    }
    const isAllSkipped = Object.values(columnsMapping).filter(value => value !== 'skipped');
    if (!isAllSkipped.length){
      notification.error({
        message: "Incomplete mapping!",
        description:
          "Before saving, kindly choose at least one option from a dropdown of any column.",
      });
      return;
    }
    setLoading(true);
    setSocketWaiting(true);
    try {
      const { data } = await Api.post(
        `/collections/${collectionData.id}/save_mapped_columns`,
        {
          mapped_columns: columnsMapping,
        }
      );
      setLoading(false);
    } catch (e: any) {
      console.log("error", e.response.data.messages[0]);
      setLoading(false);
      setSocketWaiting(false);
      const errorMsg =
        (e &&
          e.response &&
          e.response.data &&
          e.response.data.messages &&
          e.response.data.messages[0]) ||
        "Failed to map columns";
      notification.error({
        message: "Failed",
        description: errorMsg,
      });
    }
  };
  const handleDeleteCollection = async () => {
    const delRes = await deleteCollection(collectionData.id);
    if (delRes && delRes.error) {
      notification.error({
        message: "Cancel Failed",
        description: delRes.error,
      });
    } else {
      notification.success({
        message: "Cancelled",
        description: "Upload cancelled",
      });
      history.push("/collections/upload_excel_file");
    }
  };

  // console.log('mappings are', columnsMapping);
  useEffect(() => {
    socketClient.current = initiateSocket(user);
    // TODO: unmounted issue, check for ref
    socketClient.current.on("message", (msg) => {
      console.log("socket message is", msg);
      if (
        msg &&
        collectionData &&
        collectionData.id === msg.collection_id &&
        msg.message === "Process Done"
      ) {
        setSocketWaiting(false);
        history.push({
          pathname: `/collections/${collectionData.id}`,
        });
      }
    });
  }, []);
  useEffect(() => {
    let topColumns: any = [];
    try {
      // Check on uploaded columns
      topColumns =
        collectionData && collectionData?.attributes?.uploaded_columns;
    } catch (e) {}
    try {
      // IF uploaded columns not found then use original_uploaded_data
      topColumns = Object.keys(
        collectionData.attributes.document.original_uploaded_data[0]
      );
    } catch (e) {}
    if (!topColumns) {
      topColumns = [];
    }
    if (topColumns) {
      setColumns(
        topColumns.map((s, index) => ({
          title: s,
          dataIndex: s,
          key: s,
          width: 100,
          // onHeaderCell: (column) => ({
          //   width: column.width,
          //   onResize: handleResize(index),
          // }),
        }))
      );
      const _originalColumns =
        collectionData.attributes.type === "plant"
          ? collectionData.attributes.original_columns
          : collectionData.attributes.original_columns.map((orgCol) => {
              if (orgCol.value === "full_name") {
                return { label: orgCol.label, value: "scientific_names" };
              }
              return orgCol;
            });
      setOriginalColumns(_originalColumns);
      setTableData(collectionData.attributes.document.original_uploaded_data);
      const _columnsMapping = {};
      for (let singleC of topColumns) {
        const matchedColumn = _originalColumns.find((s) => s.label === singleC);
        if (matchedColumn) {
          _columnsMapping[singleC] = matchedColumn.value;
        }
      }
      setColumnsMapping(_columnsMapping);
    }
  }, [collectionData]);
  // console.log(screens);
  if (loading) {
    return <LoadingSpinnerView size={80} />;
  }
  if (socketWaiting) {
    return (
      <Result
        icon={<Spin />}
        title="Please wait"
        subTitle={
          "Mapping is in progress, larger files might take longer time. "
        }
        extra={[
          <Button type="primary" key="console">
            <a href={`/collections/${collectionData.id}`}>Refresh</a>
          </Button>,
        ]}
      />
    );
  }
  if (!collectionData || !collectionData.attributes) {
    return <Empty />;
  }
  return (
    <CollectionColumnMappingTableWrapper>
      {/* <Row style={{ paddingBottom: 10 }}>
        
      </Row> */}
      <Row style={{ paddingBottom: 10 }} gutter={[8, 8]}>
        <Col lg={17} xs={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Please review and save your list if you’re happy with the mapping.
            Alternatively, you can also manually configure the mapped list, or
            upload a new list.
          </Typography.Title>
        </Col>
        <Col lg={7} xs={24}>
          <Row style={{ justifyContent: "flex-end" }}>
            <Space>
              <Button
                type="primary"
                // style={{ backgroundColor: "red" }}
                onClick={onSave}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "red" }}
                onClick={handleDeleteCollection}
                icon={<DeleteOutlined />}
              >
                Cancel & Upload New
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Table
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{
          x: "max-content",
          y: getTableScroll({ extraHeight: null, id: null }),
        }}
        dataSource={tableData}
        pagination={{
          position: ["bottomCenter"],
        }}
        rowKey={(record, i) => record._id || `t${i}`}
        columns={columns}
      />
    </CollectionColumnMappingTableWrapper>
  );
};

export default CollectionColumnMappingTable;
