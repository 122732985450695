import { checkPassword } from "helper";

export const passwordValidatorAnt = () => ({
    validator(_, value) {
        if(!value) {
            return Promise.reject('Enter your password');
        }
      if (checkPassword(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          "Password should be length of 6-30, Include at least one lowercase, one uppercase, one numeric digit and one special character"
        )
      );
    },
  });
