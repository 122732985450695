import React, { useEffect } from 'react';
import {  Layout as AntdLayout, message} from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
// import { ThemeConfig } from 'theme';
import LoginForm from 'components/Login/LoginForm';
import * as qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
interface Props {}

const LoginView = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const queries = qs.parse(location.search);
    if (queries && queries.message) {
      message.error({ content: queries.message as string, duration: 5 });
      delete queries.message;
      history.replace({
        search: qs.stringify(queries),
      });
    }
  }, [location.search, history]);
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <LoginForm />
    </AntdLayout>
  );
};

export default LoginView;
