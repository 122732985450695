import React from 'react';
import {  Layout as AntdLayout} from "antd";

import HeaderNoAuth from '../../components/Layout/Header/HeaderNoAuth';
import ForgotPasswordForm from 'components/Login/ForgotPasswordForm';
// import { ThemeConfig } from 'theme';

interface Props {}

const ForgotPasswordView = (props: Props) => {
  return (
    <AntdLayout>
      <HeaderNoAuth />
      <ForgotPasswordForm />
    </AntdLayout>
  );
};

export default ForgotPasswordView;
