import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('slpfront_db_token');

export const setToken = token => Cookies.set('slpfront_db_token', token, { expires: 1 });

export const removeToken = () => Cookies.remove('slpfront_db_token');

export const setUserData = data => localStorage.setItem('slpfront_db_user', data);

export const getUserData: any = () => localStorage.getItem('slpfront_db_user');

export const removeUserData = () => localStorage.removeItem('slpfront_db_user');

export const checkPassword = (inputVal = '')  =>
{ 
    var check=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,30}$/;
    if(inputVal.match(check)) 
    { 
        return true;
    }
    else
    { 
        return false;
    }
}

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(/\s+/);
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

export const refresh = () => {
    window.location.reload();
}

export const truncateWithEllipsis = (s = '',maxLength) => {
   if (s.length > maxLength) {
      return s.substring(0, maxLength) + '...';
   }
   return s;
};
