import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  message,
  Alert,
  Form,
  Col,
  Row,
  notification,
  Spin,
  Radio,
  Select,
} from "antd";
import { capitalize } from 'lodash';
import Api from "api";
import styled from "styled-components";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { useStripe } from "@stripe/react-stripe-js";
import { ThemeConfig } from "theme";
import { getCheckoutSessionForCollection } from "api/helper";
import { LoadingSpinnerView } from "components/LoadingSpinner/LoadingSpinner";
import { useUser } from "helper/UserContext";
import CollectionDownloadModal from "./CollectionDownloadModal";
const DownloadWrapper = styled.div`
  // margin-top: 70px;
  // min-height: calc(100vh - 100px);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 50px 20px;
  .mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .greyLink {
    text-decoration: none;
    color: #8a8787;
    font-weight: 400;
    font-size: 14px;
  }
  .packageDescription {
    font-weight: 400 !important;
  }
  .ant-modal-body {
    padding: 5px !important;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const ExportAListModal = ({ isOpen, onClose, collectionId = null, plantCollections = [], animalCollections = [] }) => {
  const [form] = Form.useForm();
  const [body, setBody] = useState<string>("");
  const stripe = useStripe();
  const {user} = useUser();
  const [type, setType] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>("");
  const [isDownloadPurchaseOpen, setDownloadPurchaseOpen] = useState<boolean>(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState<any>(false);
  const downloadCheckLite = () => {
    if (user && user.status === "free" && !user.one_time_purchase) {
      setDownloadPurchaseOpen(true);
      return;
    }
  }
  const downloadCheck = async () => {
    try {
      if (user && user.status === "free" && !user.one_time_purchase) {
        setDownloadPurchaseOpen(true);
        return;
      }
      // return;
      const res = await Api.get(
        `/collections/${
          collectionId || selectedCollectionId
        }/download?type=xls`,
        {},
        { responseType: "blob" }
      );
      if (res && res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `collection_export.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        notification.error({
          message: "Download failed",
          description: "Failed to download excel",
        });
      }
    } catch (e: any) {
      notification.error({
        message: "Download failed",
        description: "Failed to download file",
      });
    }
  };
  useEffect(() => {
    isOpen && downloadCheckLite();
  }, [isOpen]);
  if (isDownloadPurchaseOpen) {
    return (
      <CollectionDownloadModal
        isOpen={isDownloadPurchaseOpen}
        onClose={(action) => {
          //   if (action === "refetch") {
          //     fetchCollection();
          //   }
          //   setErrorModalOpen(false);
          onClose("cancel");
          setDownloadPurchaseOpen(false);
        }}
        collectionId={collectionId || selectedCollectionId}
        // plantCollections={plantCollections}
        // animalCollections={animalCollections}
        // collection={collection}
      />
    );
  }
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  
  return (
    <Modal
      title="Export"
      width={800}
      style={{ textAlign: "center" }}
      // bodyStyle={{ padding: 10 }}
      visible={isOpen}
      onCancel={() => {
        onClose("cancel");
        // form.resetFields();
      }}
      footer={
        <Row style={{ justifyContent: "center" }}>
          <Button
            key="back"
            type="text"
            onClick={() => {
              // onClose("cancel");
                downloadCheck()
              // form.resetFields();
            }}
          >
           Download
          </Button>
        </Row>
      }
    >
      <DownloadWrapper>
        <FormWrapper>
          <Col>
            <Form
              {...layout}
              // initialValues={{ ...user }}
              form={form}
            >
              <Col span={24}>
                {!collectionId && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select collection type!",
                      },
                    ]}
                    // style={{ textAlign: "left" }}
                    label="Collection Type"
                    name="type"
                  >
                    {/* <Input placeholder="Enter collection url" /> */}
                    <Radio.Group
                      options={["animal", "plant"]}
                      size="large"
                      optionType="button"
                      style={{ width: "100%", textTransform: "capitalize" }}
                      onChange={(e) => {
                        console.log(form.getFieldsValue());
                        setType(e.target.value);
                      }}
                      // value={value1}
                    />
                  </Form.Item>
                )}
                {!!type && (
                  <Form.Item
                    label={capitalize(type)}
                    name="merge_id"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Target collection is required",
                      },
                    ]}
                  >
                    <Select
                      onChange={(ev) => {
                        console.log("changed", ev);
                        setSelectedCollectionId(ev);
                      }}
                    >
                      {(type === "plant"
                        ? plantCollections
                        : animalCollections
                      ).map((s: any) => (
                        <Select.Option key={s.id} value={s.id}>
                          {s.attributes.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Form>
          </Col>
        </FormWrapper>
      </DownloadWrapper>
    </Modal>
  );
};
export default ExportAListModal;

