import React from 'react';
import { StyledHeaderNoAuth } from "./HeaderStyles";
import { Col} from "antd";
import styled from "styled-components";
import logo from "../../../specieslistprologo.png";
import Media from 'react-media';
import EnvModeIndicator from 'components/Common/EnvModeIndicator';
interface Props {
  // toggleSidebar: Function;
  // collapse: boolean;
}

const TopNavLink = styled.li`
  list-style: none;
  &:hover a {
    color: rgba(255,255,255,0.75);
    border-top: 3px solid #0fff65;
  }
  a {
    display: block;
    font-weight: bold;
    border-top: 3px;
    border-color: rgba(111, 111, 111, 0.2) transparent transparent;
    border-style: solid;
    padding-left: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
`;

const Header: React.FC<Props> = (props) => {
  return (
     <Media
      queries={{
        small: "(max-width: 960px)",
      }}
    >
      {(matches) => {
        const isMobile = matches.small;
        return (
          <StyledHeaderNoAuth>
            <EnvModeIndicator />
            <Col sm={{ span: 24 }} lg={{ span: 16, offset: 4 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={logo} alt="Logo" style={{ width: isMobile ? 150 : 200 }} />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TopNavLink>
                    <a href="/login">Login</a>
                  </TopNavLink>
                  <TopNavLink>
                    <a href="/signup">Signup</a>
                  </TopNavLink>
                </div>
              </div>
            </Col>
          </StyledHeaderNoAuth>
        );
      }}

    </Media>
  );
};

export default Header;
